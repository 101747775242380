import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { green, magic } from 'vstui/colors'
import { ReactComponent as CompletedLarge } from 'images/module-status-complete.svg'

const Donut = ({ percentage, sm }) => {
  const { t } = useTranslation()

  let base = magic.getShade(2)
  let color = green.getShade(6)
  let center = '#ffffff'
  let deg
  let deg1
  let deg2
  let color2
  let base2
  base2 = base

  if (percentage >= 50) {
    color2 = color
    deg = (percentage / 100) * 360 + 'deg'
    deg1 = '90deg'
    deg2 = deg
  } else {
    base = color
    color = base2
    color2 = base2
    deg1 = (percentage / 100) * 360 + 90 + 'deg'
    deg2 = '0deg'
  }

  const donutStyle = {
    width: sm ? 30 : 50,
    height: sm ? 30 : 50,
    background: base,
  }

  const sliceOneStyle = {
    clip: sm ? 'rect(0 30px 15px 0)' : 'rect(0 50px 25px 0)',
    WebkitTransform: `rotate(${deg1})`,
    transform: `rotate(${deg1})`,
    background: color,
  }

  const sliceTwoStyle = {
    clip: sm ? 'rect(0 15px 30px 0)' : 'rect(0 25px 50px 0)',
    WebkitTransform: `rotate(${deg2})`,
    transform: `rotate(${deg2})`,
    background: color2,
  }

  const chartCenterStyle = {
    background: center,
  }

  return (
    <div
      className={classnames({
        'donut-chart': true,
        'donut-chart__completed': percentage === '100',
        'donut-chart__small': sm,
      })}
      style={donutStyle}
    >
      {percentage === '100' ? (
        <img aria-hidden="true" src={CompletedLarge} alt={t('donutCompleted')} />
      ) : (
        <>
          <div className="slice one" style={sliceOneStyle} />
          <div className="slice two" style={sliceTwoStyle} />
          <div className="chart-center" style={chartCenterStyle}>
            {!sm && <span>{percentage}%</span>}
          </div>
        </>
      )}
    </div>
  )
}

Donut.propTypes = {
  percentage: PropTypes.number,
  sm: PropTypes.bool,
}

export default Donut
