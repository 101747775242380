import createColorPalette from './createColorPalette'

const ocean = {
  1: '#eaf7fc',
  2: '#b0e3f4',
  3: '#75cfec',
  4: '#00a7db',
  5: '#0093c4',
  6: '#007fad',
  7: '#006c94',
  8: '#005a75',
  9: '#00465c',
  10: '#003342',
  inflection: 6,
}

export default createColorPalette(ocean, 'ocean')
