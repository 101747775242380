import createColorPalette from './createColorPalette'

const jungle = {
  1: '#e8f7f2',
  2: '#c0eadb',
  3: '#86e0c2',
  4: '#24c991',
  5: '#1a9c6e',
  6: '#16825c',
  7: '#12694a',
  8: '#0d4f38',
  9: '#0a3c2b',
  10: '#07291d',
  inflection: 6,
}

export default createColorPalette(jungle, 'jungle')
