import createColorPalette from './createColorPalette'

const purple = {
  1: '#f7f5ff',
  2: '#e3dbff',
  3: '#ccbdfe',
  4: '#bca9fa',
  5: '#9375f2',
  6: '#8956f0',
  7: '#683ac8',
  8: '#532ca6',
  9: '#3f1c86',
  10: '#2a1359',
  inflection: 6,
}

export default createColorPalette(purple, 'purple')
