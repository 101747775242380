import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from '@mui/styles'
import FormController from './FormController'
import SettingsSummary from './SettingsSummary'
import BottomActionsField from './FormModules/BottomActionsField'
import { generateExceptionFormData } from './assignmentSchedule'
import { formValidationFunction } from './formValidation'
import { formExceptionsSubmitFunction } from './formActions'
import { StoreContext } from 'store'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import AssessmentSettingsDialog from 'components/AssessmentSettingsDialog'
import { EDIT_EXCEPTIONS_DRAWER_ID } from 'utils/constants'
import { log } from 'utils/log'

const ExceptionSmartForm = ({ theme, courseKey, userException }) => {
  const { t } = useTranslation()
  const { dispatch, userAq } = useContext(StoreContext)
  const { assignmentSelection, assignmentTypes, confirmationDialogMode, dispatchSettingsAction } =
    useSettingsContext()

  const userTimezone = userAq?.time_zone || 'America/New_York'

  const { formInitialValues, formModules, defaultValues } = generateExceptionFormData(
    assignmentSelection,
    userException,
  )

  log('Exception initialValues and modules: ', formInitialValues, formModules)

  const validationFunction = (values) =>
    formValidationFunction.apply(null, [values, t, userTimezone])

  const submitFunction = (values) =>
    formExceptionsSubmitFunction.apply(null, [
      values,
      formInitialValues,
      assignmentSelection[0],
      userTimezone,
      courseKey,
      dispatch,
      dispatchSettingsAction,
      assignmentTypes,
    ])

  log('USER', userException, assignmentSelection[0])

  return (
    <>
      <FormController
        initialValues={formInitialValues}
        onSubmit={submitFunction}
        validate={validationFunction}
        className="smart-form"
        validateOnChange={false}
        validateOnBlur={false}
        timezone={userTimezone}
        showEmptySelectLabel={false}
      >
        <SettingsSummary />
        {formModules.map((FormModule, idx) => {
          return <FormModule theme={theme} key={idx} defaultValues={defaultValues} />
        })}
        <BottomActionsField drawerId={EDIT_EXCEPTIONS_DRAWER_ID} />
      </FormController>
      <AssessmentSettingsDialog mode={confirmationDialogMode} />
    </>
  )
}

export default withTheme(ExceptionSmartForm)

ExceptionSmartForm.propTypes = {
  /**
   * Course key
   */
  courseKey: PropTypes.string,
  theme: PropTypes.object,
  userException: PropTypes.object,
}

ExceptionSmartForm.defaultProps = {
  courseKey: '',
  theme: {},
  userException: {},
}
