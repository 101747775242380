import createColorPalette from './createColorPalette'

const orange = {
  1: '#fff3e3',
  2: '#ffd69b',
  3: '#ffb55f',
  4: '#ff943b',
  5: '#e65f14',
  6: '#d04a00',
  7: '#b54102',
  8: '#983702',
  9: '#7a2d00',
  10: '#541f00',
  inflection: 6,
}

export default createColorPalette(orange, 'orange')
