import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

const AssignmentEditBtn = ({
  nSelectedAssignments,
  handleEditSelections,
  handleManageExceptions,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className="assignment-edit-btn"
      aria-hidden={nSelectedAssignments === 0 ? true : false}
      style={{ opacity: nSelectedAssignments === 0 ? 0 : 1 }}
    >
      <span className="label">
        {t('assignmentEditBtn.itemsSelected', { items: nSelectedAssignments })}
      </span>
      <div className="assignment-edit-btn__buttons">
        {nSelectedAssignments === 1 && (
          <Button
            onClick={handleManageExceptions}
            disabled={nSelectedAssignments === 0}
            variant="outlined"
          >
            {t('assignmentEditBtn.manageExceptions')}
          </Button>
        )}
        <Button onClick={handleEditSelections} disabled={nSelectedAssignments === 0}>
          {t('assignmentEditBtn.editSelections')}
        </Button>
      </div>
    </div>
  )
}

export default memo(AssignmentEditBtn)

AssignmentEditBtn.propTypes = {
  /**
   * number of selected assignments
   */
  nSelectedAssignments: PropTypes.number,
  /**
   * Edit selections callback function
   */
  handleEditSelections: PropTypes.func,
  /**
   * Manage exceptions callback function
   */
  handleManageExceptions: PropTypes.func,
}

AssignmentEditBtn.defaultProps = {
  nSelectedAssignments: 0,
  handleEditSelections: () => {},
  handleManageExceptions: () => {},
}
