/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import Button from '@mui/material/Button'
import { StoreContext } from 'store'
import Newrelic from 'utils/newrelic'
import Spinner from 'components/Spinner'
import { handleLinksToBookshelf } from 'utils/learnKit'
import PageHeader from 'components/PageHeader'
import TrialAlert from 'components/TrialAlert'

const BOOKSHELF_HOST = process.env.REACT_APP_BOOKSHELF_HOST || 'bookshelf.acrobatiq.com'

const Book = (props) => {
  const { TagName, book, onClickToBookshelf } = props
  const { t } = useTranslation()
  const bookStyle = {
    backgroundImage: `url(${book.bg})`,
  }

  return (
    <TagName className="book">
      <div className="book--cover" style={bookStyle}>
        <img src={book.image} alt={book.title} />
      </div>
      <h2 className="book--title">{book.title}</h2>
      <p className="book--authors">
        <strong>{t('books.bookAuthorLabel')}</strong> {book.author}
      </p>
      <p className="book--vbid">
        <strong>{t('books.vbidLabel')}</strong> {book.isbn}
      </p>
      <Button
        href={`https://${BOOKSHELF_HOST}/#/books/${book.isbn}`}
        className="book--link"
        target="_blank"
        onClick={onClickToBookshelf}
      >
        {t('books.bookOpenLink')}
      </Button>
    </TagName>
  )
}

Book.propTypes = {
  book: PropTypes.object,
  onClickToBookshelf: PropTypes.func,
  TagName: PropTypes.string,
}

Book.defaultProps = {
  TagName: 'li',
}

const Books = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const { course, dispatch, userAq, userLk } = useContext(StoreContext)
  const { courseKey } = useParams()
  const { t } = useTranslation()

  const books =
    course &&
    course.learnkitBookInfo.map((data) => {
      data.image = data.coverURL.replace(':width', `320`)
      data.bg = data.coverURL.replace(':width', `20`)
      return data
    })

  const onClickToBookshelf = (e) => {
    const linkHref = e.currentTarget.href
    handleLinksToBookshelf({
      e,
      linkHref,
      isAuthenticating,
      setIsAuthenticating,
      userAq,
      userLk,
      dispatch,
      courseKey,
    })
  }

  useEffect(() => {
    if (course && course.title) document.title = course.title
  }, [course])

  useEffect(() => {
    Newrelic.setPageViewName('course/books')
  }, [])

  if (!course) return <Spinner />

  return (
    <main
      id="main"
      role="main"
      aria-hidden="false"
      aria-label={t('nav.mainContent')}
      tabIndex="-1"
      className="px-0"
    >
      <PageHeader>
        <h1 className="courses-mast__title">{t('books.pageTitle')}</h1>
      </PageHeader>

      <Container>
        <>
          {isAuthenticating && <Spinner />}
          <TrialAlert courseKey={courseKey} />
          <ul className="books">
            {books.map((book) => (
              <Book book={book} key={book.isbn} onClickToBookshelf={onClickToBookshelf} />
            ))}
          </ul>
        </>
      </Container>
    </main>
  )
}

export default Books
