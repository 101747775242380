import createColorPalette from './createColorPalette'

const pickle = {
  1: '#f4f6ea',
  2: '#e0eab8',
  3: '#ccde87',
  4: '#a3c523',
  5: '#7e9e06',
  6: '#637d05',
  7: '#536904',
  8: '#415203',
  9: '#303d02',
  10: '#202902',
  inflection: 6,
}

export default createColorPalette(pickle, 'pickle')
