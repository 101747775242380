import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { StoreContext } from 'store'
import AlertMessage from 'components/AlertMessage'
import Autocomplete from 'components/Forms/FormComponents/Autocomplete'
import DatePicker from 'components/Forms/FormComponents/DatePicker'
import TextEdit from 'components/Forms/FormComponents/TextEdit'
import Spinner from 'components/Spinner'
import { validEmailRegex } from 'utils/regexes'
import { FIELDS } from './formDefinitions'
import useAdoptions from './useAdoptions'
import { useFormStyles } from './useFormStyles'
import { isDateTimeFieldValid } from 'components/Forms/smartFormHelpers'
// import RemindMeNextTermCheckField from './FormModules/RemindMeNextTermCheckField'

const CreateNewAdoptionsForm = ({ theme }) => {
  const { t } = useTranslation()
  const COURSEWARE_NAME_MAX_LENGTH = 600
  const { customerInstructors, dispatch, userAq, userPublisherBlueprints, userPublisherCustomers } =
    useContext(StoreContext)
  const { FormModule } = useFormStyles(theme)
  const [componentKey, setComponentKey] = useState(Date.now())
  const {
    createAdoption,
    getCreateNewAdoptionsFormRequiredData,
    getCustomerInstructors,
    getMessage,
    getMessageType,
    hasErrorsLoadingRequiredData,
    hasSubmittedWithErrors,
    hasSuccessfullySubmitted,
    isApiLoading,
    isLoadingInstructors,
    isLoadingRequiredData,
    isSubmitting,
    shouldDisplayAlert,
    unsetCreateNewAdoptionsFormData,
    unsetCustomerInstructors,
    unsetUserPublisherBlueprints,
    unsetUserPublisherCustomers,
  } = useAdoptions()

  // Workaround to avoid Chrome autofill dropdown
  const fakeEmailFieldName = useRef(`field-${Date.now()}`)

  const initialValues = {
    [FIELDS.institution.name]: '',
    [FIELDS.courseware.name]: '',
    [FIELDS.titleOfCourseware.name]: '',
    [FIELDS.startDate.name]: null,
    [FIELDS.endDate.name]: null,
    [FIELDS.remindMeNextTerm.name]: null,
    // Workaround to avoid Chrome autofill dropdown
    // [FIELDS.instructorEmail.name]: [],
    [fakeEmailFieldName.current]: [],
  }

  const validateForm = (values) => {
    const errors = {}

    // Validate required fields
    const requiredFieldsNames = [
      [FIELDS.institution.name],
      [FIELDS.courseware.name],
      [FIELDS.titleOfCourseware.name],
      [FIELDS.startDate.name],
      [fakeEmailFieldName.current],
    ]

    requiredFieldsNames.forEach((fieldName) => {
      if (
        (typeof values[fieldName] === 'string' && values[fieldName].trim().length === 0) ||
        !values[fieldName]
      ) {
        errors[fieldName] = t('generics.forms.pleaseFillThisField')
      }
    })

    // Validate dates
    const isFieldValid = {
      [FIELDS.startDate.name]: isDateTimeFieldValid(moment(values[FIELDS.startDate.name])),
      [FIELDS.endDate.name]: isDateTimeFieldValid(moment(values[FIELDS.endDate.name])),
    }
    // Start date picker validation
    if (!isFieldValid[FIELDS.startDate.name]) {
      errors[FIELDS.startDate.name] = t('formValidation.invalidDate')
    } else {
      // Delete error if valid
      delete errors[FIELDS.startDate.name]
    }
    // End date picker validation
    delete errors[FIELDS.endDate.name]

    // End date picker validation
    if (isFieldValid[FIELDS.endDate.name]) {
      const momentStartDate = moment(values[FIELDS.startDate.name])
      const momentEndDate = moment(values[FIELDS.endDate.name])
      if (momentEndDate < momentStartDate) {
        errors[FIELDS.endDate.name] = t('formValidation.startDateAfterDueDate')
      }
    } else {
      // No date validation errors if not definedDate
      delete errors[FIELDS.endDate.name]
    }

    // Validate instructor emails
    const instructorEmails = values[fakeEmailFieldName.current]

    if (
      (typeof instructorEmails === 'string' && instructorEmails.trim().length === 0) ||
      !instructorEmails ||
      !instructorEmails.length
    ) {
      errors[fakeEmailFieldName.current] = t('generics.forms.pleaseFillThisField')
      return errors
    }

    for (const email of instructorEmails) {
      const emailString = typeof email === 'string' ? email : email.value
      if (emailString?.trim().length > 0 && !validEmailRegex.test(emailString)) {
        errors[fakeEmailFieldName.current] = t('generics.forms.invalidEmail')
        break
      }
    }

    return errors
  }

  const getEmailFromInstructorValue = (instructor) =>
    typeof instructor === 'string' ? instructor : instructor?.value || instructor?.label

  const submitForm = (values) => {
    const publisherSlug = userAq.publisher_slug

    // Workaround to avoid Chrome autofill dropdown
    const instructorEmailFieldName = fakeEmailFieldName.current

    const instructors = values[instructorEmailFieldName].map((instructor) => ({
      email: getEmailFromInstructorValue(instructor),
      first_name: instructor?.firstName,
      last_name: instructor?.lastName,
      role: 'Instructor',
    }))

    const data = {
      customer: publisherSlug,
      plan_id: `plan_id_${Date.now()}`,
      user: userAq.email,
      courses: [
        {
          create: true,
          course_section_id: `course_section_id_${Date.now()}`,
          customer: values.institution.inputValue || values.institution.label,
          end_date: values.endDate?.toISOString(),
          platform_blueprint_id: values.courseware?.value,
          platform_customer_id: values.institution?.value,
          start_date: values.startDate?.toISOString(),
          title: values.titleOfCourseware,
          users: instructors,
        },
      ],
    }

    createAdoption({ dispatch, publisherSlug, data })
  }

  const resolveSelectOptions = useCallback(() => {
    let institutionOptions,
      blueprintsOptions,
      customerInstructorsOptions = []
    if (isApiLoading) return { institutionOptions, blueprintsOptions, customerInstructorsOptions }

    if (userPublisherCustomers) {
      institutionOptions = userPublisherCustomers
        ? userPublisherCustomers.map((opt) => ({
            id: opt.id,
            value: opt.slug,
            label: opt.name,
          }))
        : []
    }

    if (userPublisherBlueprints) {
      blueprintsOptions = userPublisherBlueprints
        ? userPublisherBlueprints
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((opt) => ({
              id: opt.identifier,
              value: `${opt.projectSlug}::${opt.identifier}`,
              label: opt.title,
              customOptionLabel: `${t('adoptions.project')} ${opt.projectTitle}`,
            }))
        : []

      if (customerInstructors) {
        customerInstructorsOptions = customerInstructors
          ? customerInstructors
              .filter((instructor) => instructor.email)
              .sort((a, b) => a.email.localeCompare(b.email))
              .map((opt) => ({
                value: opt.email,
                label: opt.email,
                id: opt.id,
                firstName: opt.firstName,
                lastName: opt.lastName,
              }))
          : []
      }
    }

    return { institutionOptions, blueprintsOptions, customerInstructorsOptions }
  }, [isApiLoading, userPublisherBlueprints, userPublisherCustomers, customerInstructors, t])

  const { institutionOptions, blueprintsOptions, customerInstructorsOptions } =
    resolveSelectOptions()

  const coursewareCustomOptionLabel = (option) => (
    <div>
      <strong>{option.label}</strong>
      <br />
      <span style={{ fontSize: '14px' }}>{option.customOptionLabel}</span>
    </div>
  )

  const handleInstitutionChange = (newValue) => {
    if (newValue && newValue?.id) {
      getCustomerInstructors({ dispatch, customerId: newValue.id })
    } else {
      unsetCustomerInstructors({ dispatch })
    }
  }

  const handleCoursewareChange = (newValue, formikProps) => {
    if (newValue) {
      formikProps.setFieldValue(FIELDS.titleOfCourseware.name, newValue.label)
    } else {
      formikProps.setFieldValue(
        FIELDS.titleOfCourseware.name,
        initialValues[FIELDS.titleOfCourseware.name],
      )
    }

    formikProps.setFieldTouched(FIELDS.titleOfCourseware.name, false, false)
  }

  useEffect(() => {
    getCreateNewAdoptionsFormRequiredData({ dispatch })
  }, [dispatch, getCreateNewAdoptionsFormRequiredData])

  useEffect(() => {
    if (hasSuccessfullySubmitted) {
      setComponentKey(Date.now())
      window.requestAnimationFrame(() => window.scrollTo(0, 0))
    } else if (hasSubmittedWithErrors) {
      window.requestAnimationFrame(() => window.scrollTo(0, 0))
    }
  }, [dispatch, hasSubmittedWithErrors, hasSuccessfullySubmitted])

  useEffect(() => {
    return function cleanup() {
      unsetCustomerInstructors({ dispatch })
      unsetUserPublisherCustomers({ dispatch })
      unsetUserPublisherBlueprints({ dispatch })
      unsetCreateNewAdoptionsFormData({ dispatch })
    }
  }, [
    dispatch,
    unsetCreateNewAdoptionsFormData,
    unsetCustomerInstructors,
    unsetUserPublisherBlueprints,
    unsetUserPublisherCustomers,
  ])

  if (isLoadingRequiredData) {
    return (
      <div style={{ margin: '20px' }}>
        <Spinner size="sm" position="center" />
      </div>
    )
  }

  return (
    <>
      {shouldDisplayAlert && <AlertMessage color={getMessageType()}>{getMessage()}</AlertMessage>}
      {!hasErrorsLoadingRequiredData && (
        <Formik
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={submitForm}
          validateOnChange={false}
          validateOnBlur={false}
          key={componentKey}
        >
          {(formikProps) => {
            const startDate = formikProps.values[FIELDS.startDate.name]
            const afterStartDate = startDate
              ? moment(startDate.toISOString()).add(1, 'd').toISOString()
              : null

            return (
              <Form className="form-controller" autoComplete="off">
                <FormModule>
                  <Autocomplete
                    name={FIELDS.institution.name}
                    label={t(FIELDS.institution.i18nLabel)}
                    helperText={t(FIELDS.institution.i18nHelperText)}
                    disabled={isApiLoading}
                    theme={theme}
                    options={institutionOptions}
                    onChange={handleInstitutionChange}
                    allowAddingNewOptions={true}
                    required
                  />
                </FormModule>
                <FormModule>
                  <Autocomplete
                    name={FIELDS.courseware.name}
                    label={t(FIELDS.courseware.i18nLabel)}
                    helperText={t(FIELDS.courseware.i18nHelperText)}
                    disabled={isApiLoading}
                    theme={theme}
                    options={blueprintsOptions}
                    onChange={(value) => handleCoursewareChange(value, formikProps)}
                    showSubTitle={true}
                    required
                    customOptionLabel={coursewareCustomOptionLabel}
                  />
                </FormModule>
                <FormModule>
                  <TextEdit
                    name={FIELDS.titleOfCourseware.name}
                    label={t(FIELDS.titleOfCourseware.i18nLabel)}
                    helperText={t(FIELDS.titleOfCourseware.i18nHelperText)}
                    disabled={isApiLoading}
                    theme={theme}
                    autoComplete="off"
                    maxLength={COURSEWARE_NAME_MAX_LENGTH}
                    required
                  />
                </FormModule>
                <FormModule className="dateTime">
                  <div className="pickers">
                    <DatePicker
                      name={FIELDS.startDate.name}
                      label={t(FIELDS.startDate.i18nLabel)}
                      helperText={t(FIELDS.startDate.i18nHelperText)}
                      disabled={isApiLoading}
                      theme={theme}
                      required
                    />
                    <DatePicker
                      name={FIELDS.endDate.name}
                      label={t(FIELDS.endDate.i18nLabel)}
                      helperText={t(FIELDS.endDate.i18nHelperText)}
                      disabled={isApiLoading}
                      theme={theme}
                      min={afterStartDate || ''}
                    />
                  </div>
                </FormModule>
                {/* <RemindMeNextTermCheckField disabled={isApiLoading} theme={theme} /> */}
                <FormModule>
                  <Autocomplete
                    name={fakeEmailFieldName.current}
                    label={t(FIELDS.instructorEmail.i18nLabel)}
                    helperText={t(FIELDS.instructorEmail.i18nHelperText)}
                    disabled={isApiLoading}
                    theme={theme}
                    options={customerInstructorsOptions}
                    allowAddingNewOptions={true}
                    loading={isLoadingInstructors}
                    multiple
                    required
                    type="email"
                    // Use Institution as key, so this field resets when Institution changes
                    key={
                      formikProps.values[FIELDS.institution.name]?.id || fakeEmailFieldName.current
                    }
                    // Workaround to avoid Chrome autofill dropdown
                    inputAutoComplete="new-password"
                  />
                </FormModule>
                <FormModule>
                  <Button type="submit" disabled={isApiLoading}>
                    {isSubmitting ? (
                      <Spinner position="left" width={25} height={25} />
                    ) : (
                      t('adoptions.submit')
                    )}
                  </Button>
                </FormModule>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default CreateNewAdoptionsForm

CreateNewAdoptionsForm.propTypes = {
  theme: PropTypes.object,
}

CreateNewAdoptionsForm.defaultProps = {
  theme: {},
}
