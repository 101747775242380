import { PLATFORM_HOST } from 'utils/constants'

const buildUrlsPatterns = (i18n) => {
  const langCode = i18n.language.toLowerCase()
  return {
    addCoursePageUrl: `/courses/add`,
    assignmentPageUrl: `/courses/:courseKey:/assignment/:assignmentType:/:assignmentIdentifier:`,
    changePasswordUrl: `/profile/changepassword`,
    chooseCourseSectionPageUrl: `/:customerSlug:/:vbid:/choose-course-section`,
    contentPageUrl: `/courses/:courseKey:/page/:pageIdentifier:`,
    courseBooksUrl: `/courses/:courseKey:/books`,
    platformCourseManagePageUrl: `:PLATFORM_HOST:/${langCode}/coursemanage/gradebook/:courseKey:`,
    coursePageUrl: `/courses/:courseKey:`,
    coursewarePageUrl: `/courseware`,
    coursesPageUrl: `/courses`,
    courseUnitUrl: `/courses/:courseKey:/unit/:unitIdentifier:`,
    platformDashboardPageUrl: `:PLATFORM_HOST:/${langCode}/dashboard/home/:courseKey:`,
    dashboardPageUrl: `/courses/:courseKey:/dashboard`,
    forgotPasswordPageUrl: `/forgot-password/:customerSlug:`,
    logout: `:PLATFORM_HOST:/logout`,
    modulePageUrl: `/courses/:courseKey:/unit/:unitIdentifier:/module/:moduleIdentifier:`,
    payCoursePageUrl: `/courses/:courseKey:/pay`,
    profilePageUrl: `/profile`,
    settingsPageUrl: `/courses/:courseKey:/settings`,
    signInPageUrl: `/:customerSlug:/:next:`,
    signUpPageUrl: `/signup/:customerSlug:`,
    platformStudentGradebookPageUrl: `:PLATFORM_HOST:/${langCode}/coursemanage/student_gradebook/:courseKey:`,
    gradebookPageUrl: `/courses/:courseKey:/gradebook`,
    termsPageUrl: `/tos:next:`,
    courseSettingsPageUrl: `:PLATFORM_HOST:/${langCode}/coursemanage/manager/:courseKey:/#course-settings`,
    viewStudentWorkPageUrl: `:PLATFORM_HOST:/${langCode}/cia/mentordashboard/:courseKey:`,
    exitViewStudentWorkUrl: `:PLATFORM_HOST:/${langCode}/mentor/stop/?next=/`,
    studentWorkPageUrl: `/courses/:courseKey:/studentwork`,
  }
}

const replaceHost = (url, includeBasePath) =>
  url.replace(':PLATFORM_HOST:', includeBasePath ? PLATFORM_HOST : '')

const buildUrl =
  (url) =>
  (params, includeBasePath = false) => {
    if (!params) {
      return replaceHost(url, includeBasePath)
    }

    let baseUrl = Object.keys(params).reduce(
      (urlResult, paramName) =>
        urlResult.replace(`:${paramName}:`, params[paramName] || '').replace('//', '/'),
      url,
    )

    // for consistency, remove trailing slashes
    if (baseUrl.endsWith('/') && baseUrl !== '/') {
      baseUrl = baseUrl.slice(0, -1)
    }

    return replaceHost(baseUrl, includeBasePath)
  }

const buildUrls = (i18n) => {
  const patterns = buildUrlsPatterns(i18n)
  return Object.keys(patterns).reduce((result, urlKey) => {
    const pattern = patterns[urlKey]
    result[urlKey] = buildUrl(pattern)
    return result
  }, {})
}

export default buildUrls
