import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { withTheme } from '@mui/styles'
// import MastWaves from 'components/MastWaves'

const PageMast = ({ title, theme }) => {
  const color = theme.palette.primary

  const pageMastStyle = {
    backgroundColor: color.getShade(7),
  }

  return (
    <div className="page-mast" style={pageMastStyle}>
      <Container className="p-0">
        <h1 className="page-mast__title">
          <>{title}</>
        </h1>
      </Container>
      {/* TODO: Confirm if we will need the Waves here */}
      {/* <MastWaves /> */}
    </div>
  )
}

PageMast.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.object,
}

export default withTheme(PageMast)
