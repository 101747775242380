import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useTranslation, Trans } from 'react-i18next'
import { useStateWithLocalStorage } from 'utils/customHooks'

const CookiePolicyBanner = () => {
  const { t, i18n } = useTranslation()
  i18n.reloadResources()
  const [aqCookiePolicy, setAqCookiePolicy] = useStateWithLocalStorage('aqCookiePolicy')
  const [bannerTransitionClass, setBannerTransitionClass] = useState('')

  const aqCookiePolicyJson = aqCookiePolicy && JSON.parse(aqCookiePolicy)
  const showBanner = !aqCookiePolicyJson || !aqCookiePolicyJson.policy_accepted
  const bannerCssClasses = `cookie-policy-banner ${bannerTransitionClass}`

  const langCode = i18n.language.toLowerCase()
  const policyLink = `https://support.vitalsource.com/hc/${langCode}/articles/360003928273`

  const acceptPolicy = () => {
    onBannerTransitionEnd(() => {
      setAqCookiePolicy(JSON.stringify({ policy_accepted: true, timestamp: Date.now() }))
    })
    setBannerTransitionClass('')
  }

  const onBannerTransitionEnd = (callback) => {
    const banner = document.getElementById('cookie-policy-banner')
    const listenTransitionEnd = (event) => {
      if (event.propertyName === 'transform' && event.target.id === 'cookie-policy-banner') {
        banner.removeEventListener('transitionend', listenTransitionEnd)
        callback()
      }
    }
    // this will be executed at the end of banner transition
    banner && banner.addEventListener('transitionend', listenTransitionEnd)
  }

  useEffect(() => {
    if (showBanner) {
      setBannerTransitionClass('show')
    }
  }, [showBanner])

  if (!showBanner) return null

  return (
    <div className={bannerCssClasses} id="cookie-policy-banner">
      <p>
        <Trans i18nKey="cookiePolicyBanner.message">
          We use cookies so you get the best experience on our website. By using our site, you are
          agreeing to our{' '}
          <a href={policyLink} target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
          .
        </Trans>
        <Button role="button" onClick={acceptPolicy}>
          {t('cookiePolicyBanner.acceptAndClose')}
        </Button>
      </p>
    </div>
  )
}

export default CookiePolicyBanner
