import createColorPalette from './createColorPalette'

const silver = {
  1: '#fafafa',
  2: '#f6f6f6',
  3: '#ececec',
  4: '#dadada',
  5: '#949494',
  6: '#757575',
  7: '#646464',
  8: '#525252',
  9: '#3c3c3c',
  10: '#1c1c1c',
  inflection: 6,
}

export default createColorPalette(silver, 'silver')
