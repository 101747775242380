import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { setConfirmationDialogMode } from 'pages/Settings/store/actions'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { withTheme } from '@mui/styles'

const CurrentTimezone = ({ theme, timezone, catchLinkClick, showAsterisk = false }) => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { profilePageUrl } = buildPagesEndpoints(i18n)
  const { dispatchSettingsAction = null } = useSettingsContext()
  const linkColor = theme.palette.primary.getShade(6)
  const linkStyles = {
    color: linkColor,
  }

  const handleLinkClick = (e) => {
    if (catchLinkClick && dispatchSettingsAction) {
      e.preventDefault()
      setConfirmationDialogMode({
        dispatchSettingsAction,
        mode: { mode: 'cancel', callback: () => navigate(profilePageUrl()) },
      })
    }
  }

  return (
    <p className="current-timezone">
      {showAsterisk && <>* </>}
      <Trans i18nKey="currentTimezone">
        Dates and times shown reflect{' '}
        <Link
          to={profilePageUrl()}
          className="current-timezone__link"
          onClick={handleLinkClick}
          style={linkStyles}
        >
          your current timezone
        </Link>
      </Trans>
      {`: ${timezone}`}
    </p>
  )
}

export default withTheme(CurrentTimezone)

CurrentTimezone.propTypes = {
  /**
   * Timezone string (from `userAq`)
   */
  timezone: PropTypes.string,
  /**
   * Boolean for whether showing confirmation dialog before going to profile page
   */
  catchLinkClick: PropTypes.bool,
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  /**
   * Boolean for whether showing asterisk before timezone note
   */
  showAsterisk: PropTypes.bool,
}

CurrentTimezone.defaultProps = {
  timezone: '[TIMEZONE]',
  catchLinkClick: false,
  theme: {},
  showAsterisk: false,
}
