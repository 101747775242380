import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { StoreContext } from 'store'
import { apiStatus } from 'store/api'
import { actions, getCourses } from 'store/actions'

const Styled = {
  Select: styled(Select)(({ theme }) => ({
    maxWidth: '350px',
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.common.white,
    },
    '& fieldset > legend': {
      height: '8px',
    },
  })),
}

const findCourseByCourseKey = (courses, courseKey) =>
  courses?.find((course) => course.courseKey === courseKey)

const CourseSelector = ({ currentCourseKey, labelledBy, onChange, ...rest }) => {
  const { courses, dispatch, userAq } = useContext(StoreContext)
  const [currentCourse, setCurrentCourse] = useState(
    findCourseByCourseKey(courses, currentCourseKey),
  )

  const isAdmin = !!userAq && userAq?.is_admin

  const coursesList = [...(courses || [])].filter((course) => {
    // Include 5.x courses only
    const is5xCourse = !course.legacyUrl

    // If not Admin, only include courses where current user is instructor
    const isCurrentUserAdminOrInstructorOfThisCourse = isAdmin
      ? true
      : course.instructors &&
        course.instructors.length > 0 &&
        course.instructors.filter((instructor) => instructor.id === userAq.id).length > 0

    if (is5xCourse && isCurrentUserAdminOrInstructorOfThisCourse) {
      return true
    }

    return false
  })

  const coursesStatus = apiStatus({
    action: actions.COURSES_GET_REQUEST,
    isEmpty: !courses,
    isLoaded: courses !== null,
  })

  const handleChange = (e) => {
    const selectedCourse = findCourseByCourseKey(courses, e.target.value)
    setCurrentCourse(selectedCourse)
    if (typeof onChange === 'function') {
      onChange(selectedCourse)
    }
  }

  useEffect(() => {
    if (coursesStatus.shouldLoad) getCourses({ dispatch })
  }, [coursesStatus.shouldLoad, dispatch])

  useEffect(() => {
    if (courses) {
      setCurrentCourse(findCourseByCourseKey(courses, currentCourseKey))
    }
  }, [courses, currentCourseKey])

  if (!currentCourse || !courses) {
    return null
  }

  return (
    <Styled.Select
      labelId={labelledBy}
      onChange={handleChange}
      label={currentCourse?.title}
      value={currentCourse?.courseKey}
      size="small"
      {...rest}
    >
      {coursesList.map(({ courseKey, title }) => (
        <MenuItem key={courseKey} value={courseKey}>
          {title}
        </MenuItem>
      ))}
    </Styled.Select>
  )
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.label && !props.labelledBy) {
    return new Error(`One of 'label' or 'labelledBy' is required by '${componentName}' component.`)
  }
}

CourseSelector.propTypes = {
  currentCourseKey: PropTypes.string,
  onChange: PropTypes.func,
  label: requiredPropsCheck,
  labelledBy: requiredPropsCheck,
}

export default CourseSelector
