export const settingsInitialState = {
  assignmentSelection: [],
  unitOrModuleSelection: null,
  assignmentTagsActions: null,
  assignmentTagsState: null,
  assignmentTypes: null,
  closeSettingsDrawer: null,
  confirmationDialogMode: {},
  isSettingsDrawerOpen: {},
  isFormDirty: false,
}
