import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material/styles'
import errorIcon from 'images/overdue.svg'
import { FormHelperText, Box } from '@mui/material'
import { getMomentJsLocale } from 'utils/muiLocalization'

const CustomDatePicker = styled(Box)(({ theme }) => ({
  '& .MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputLabel-root': {
    position: 'relative',
    transform: 'none !important',
    color: '#1C1C1C',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: `0 0 ${theme.spacing(1)}`,
  },
  '& .MuiInputBase-formControl': {
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10.5px 14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#1C1C1C !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#1C1C1C !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.common.white,
    '& > div': {
      color: '#1C1C1C',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    '&.Mui-error': {
      borderColor: `${theme.palette.error.main} !important`,
      '& svg': {
        fill: `${theme.palette.error.main} !important`,
      },
    },
    '&.Mui-disabled': {
      // borderColor: `${theme.palette.error.main} !important`,
      '& svg': {
        fill: `${theme.palette.grey[500]} !important`,
      },
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: theme.spacing(0.5),
    '&.Mui-error': {
      marginLeft: '24px',
      color: `${theme.palette.error.main} !important`,
      '&::before': {
        content: "''",
        backgroundImage: `url(${errorIcon})`,
        transform: 'translate(-20px, 3px)',
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '0',
      },
    },
  },
}))

const DatePicker = ({
  required,
  onChange,
  helperText,
  format,
  readOnly,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  errorStrings,
  className,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { i18n } = useTranslation()
  const currentLang = i18n.language

  const momentJsLocale = getMomentJsLocale(currentLang)

  const onInputChange = (value, context) => {
    if (context.validationError == null && value?.format()) {
      field.onChange(value.format())
      helpers.setValue(value)
      onChange({ value })
    } else {
      helpers.setValue(value)
      onChange({ error: context.validationError, value })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={momentJsLocale}>
      <CustomDatePicker>
        <MaterialDatePicker
          id={props.name}
          label={required ? `${props.label} *` : props.label}
          value={moment(field.value)}
          onChange={onInputChange}
          slotProps={{ textField: { error: meta.error && meta.touched ? true : false } }}
          onBlur={field.onBlur}
          format={format}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disabled={props.disabled}
          readOnly={readOnly}
          className={className}
        />
        {(meta.error || helperText) && (
          <FormHelperText error={!!(meta.error && meta.touched)}>
            {meta.error && meta.touched ? meta.error : helperText}
          </FormHelperText>
        )}
      </CustomDatePicker>
    </LocalizationProvider>
  )
}

export default DatePicker

DatePicker.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  minDate: PropTypes.shape({}),
  maxDate: PropTypes.shape({}),
  errorStrings: PropTypes.shape({}),
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string,
}

DatePicker.defaultProps = {
  required: false,
  disabled: false,
  onChange: () => {},
  format: 'MM/DD/YYYY',
  className: null,
  readOnly: false,
  minDate: null,
  maxDate: null,
  disableFuture: false,
  disabledPast: false,
  errorStrings: {
    maxDate: 'Date exceeds maximum date.',
    minDate: 'Date precedes minimum date.',
    disablePast: 'Past dates are disabled.',
    disableFuture: 'Future dates are disabled.',
    invalidDate: 'Date is invalid',
  },
  helperText: null,
}
