import createColorPalette from './createColorPalette'

const red = {
  1: '#fff2f4',
  2: '#ffe1e6',
  3: '#fabcc5',
  4: '#f78394',
  5: '#ed596f',
  6: '#e71d3a',
  7: '#d21c35',
  8: '#a9172c',
  9: '#7e1021',
  10: '#5c0c18',
  inflection: 6,
}

export default createColorPalette(red, 'red')
