import createColorPalette from './createColorPalette'

const mist = {
  1: '#ebf9fc',
  2: '#c9e5ea',
  3: '#acd5dd',
  4: '#6eadba',
  5: '#5f96a1',
  6: '#4f7e87',
  7: '#41666e',
  8: '#324e54',
  9: '#273e42',
  10: '#1d2d30',
  inflection: 6,
}

export default createColorPalette(mist, 'mist')
