import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'reactstrap'
import { withTheme } from '@mui/styles'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StoreContext } from 'store'
import { actionHasErrors } from 'store/selectors'
import ErrorPage from 'components/ErrorPage'
import Spinner from 'components/Spinner'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { DEFAULT_CUSTOMER } from 'utils/constants'
import { useCustomerData, useStateWithLocalStorage } from 'utils/customHooks'
import AcrobatiqLogo from 'images/logo-acrobatiq-by-vitalsource.png'
import VitalSourceLogo from 'images/vst-logo.svg'

const BrandedAuthForm = ({ theme, customerSlug, children, hasVbid, forceDefaultBranding }) => {
  const { t, i18n } = useTranslation()
  const { api, brandedSigninCustomerData } = useContext(StoreContext)
  const [, setBrandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')
  const [, setLoggedInWithVitalSource] = useStateWithLocalStorage('loggedInWithVitalSource')
  const [isCustomerDataReady] = useCustomerData({ customerSlug })
  const hasCustomerErrors = actionHasErrors(api, 'CUSTOMER_GET')

  const isVitalSourceSignIn = customerSlug && customerSlug === 'vitalsource'
  const isBranded =
    forceDefaultBranding === false &&
    customerSlug !== undefined &&
    customerSlug !== 'aqpublisher' &&
    customerSlug !== 'vitalsource'

  const { signInPageUrl } = buildPagesEndpoints(i18n)
  const vitalSourceData = {
    customer: {
      customerImage: VitalSourceLogo,
      customerName: 'VitalSource',
    },
  }

  const { customerImage, customerName } = isVitalSourceSignIn
    ? vitalSourceData.customer
    : brandedSigninCustomerData

  const mainTagStyle = {
    backgroundColor: theme.palette.primary.getShade(7),
  }

  // effects

  useEffect(() => {
    if (isVitalSourceSignIn) {
      setLoggedInWithVitalSource(true)
    } else {
      setBrandedSignInCustomer(customerSlug || DEFAULT_CUSTOMER)
      setLoggedInWithVitalSource(false)
    }
  }, [customerSlug, isVitalSourceSignIn, setBrandedSignInCustomer, setLoggedInWithVitalSource])

  useEffect(() => {
    return function cleanup() {
      setLoggedInWithVitalSource(false)
    }
  }, [setLoggedInWithVitalSource])

  if (!isCustomerDataReady) return <Spinner />

  if (hasCustomerErrors) return <ErrorPage />

  return (
    <main
      id="main"
      role="main"
      aria-hidden="false"
      aria-label={t('nav.mainContent')}
      tabIndex="-1"
      className="full-page"
      style={mainTagStyle}
    >
      <div className="signin__container">
        <Link
          to={signInPageUrl({ customerSlug, next: null })}
          className={classnames({
            'signin__customer-logo-container': true,
            'signin__customer-logo-container--two-logos': (isBranded && hasVbid) || !isBranded,
          })}
        >
          {!isBranded && (
            <>
              <img src={VitalSourceLogo} alt="VitalSource" className="signin__customer-logo" />
              <div className="separator" />
              <img src={AcrobatiqLogo} alt="Acrobatiq" className="signin__customer-logo" />
            </>
          )}
          {isBranded && !hasVbid && (
            <>
              <img src={customerImage} alt={customerName} className="signin__customer-logo" />
            </>
          )}
          {isBranded && hasVbid && (
            <>
              <img src={customerImage} alt={customerName} className="signin__customer-logo" />
              <div className="separator" />
              <img src={AcrobatiqLogo} alt="Acrobatiq" className="signin__customer-logo" />
            </>
          )}
        </Link>
        <Form className="form signin__form">{children}</Form>
      </div>
    </main>
  )
}

BrandedAuthForm.propTypes = {
  children: PropTypes.node,
  customerSlug: PropTypes.string,
  forceDefaultBranding: PropTypes.bool,
  form: PropTypes.string,
  hasVbid: PropTypes.bool,
  theme: PropTypes.object,
}

BrandedAuthForm.defaultProps = {
  forceDefaultBranding: false,
}

export default withTheme(BrandedAuthForm)
