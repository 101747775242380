import createColorPalette from './createColorPalette'

const brick = {
  1: '#fef3f1',
  2: '#fac3bb',
  3: '#faa094',
  4: '#fa7d6d',
  5: '#eb4f3b',
  6: '#d24634',
  7: '#b83e2e',
  8: '#9e3528',
  9: '#7a291f',
  10: '#571d16',
  inflection: 6,
}

export default createColorPalette(brick, 'brick')
