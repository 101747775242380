import createColorPalette from './createColorPalette'

const gold = {
  1: '#fff5dd',
  2: '#ffe29c',
  3: '#ffd560',
  4: '#e8b520',
  5: '#be8a0d',
  6: '#9a6f07',
  7: '#855f07',
  8: '#715005',
  9: '#5a4206',
  10: '#3b2b04',
  inflection: 6,
}

export default createColorPalette(gold, 'gold')
