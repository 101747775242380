import createColorPalette from './createColorPalette'

const frost = {
  1: '#f6f8fc',
  2: '#edf0f5',
  3: '#dfe4eb',
  4: '#ced2d9',
  5: '#888c94',
  6: '#71747a',
  7: '#5b5e63',
  8: '#46494d',
  9: '#343538',
  10: '#1c1d1f',
  inflection: 6,
}

export default createColorPalette(frost, 'frost')
