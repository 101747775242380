import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { ReactComponent as AnswersIcon } from 'images/icon-answers.svg'
import { ReactComponent as CommentIcon } from 'images/icon-comment.svg'
import { ReactComponent as EditIcon } from 'images/icon-edit.svg'

const AuthorToolbar = ({
  sendEditRequest,
  sendToggleComments,
  sendToggleAnswerKey,
  showEditButton,
  showCommentsButton,
  showAnswerKey,
}) => {
  const { t } = useTranslation()

  return (
    <div className="author-toolbar">
      <ul>
        {showEditButton && (
          <li>
            <Button title={t('authorToolbar.edit')} onClick={sendEditRequest}>
              <EditIcon />
            </Button>
          </li>
        )}
        {showCommentsButton && (
          <li>
            <Button title={t('authorToolbar.comments')} onClick={sendToggleComments}>
              <CommentIcon />
            </Button>
          </li>
        )}
        {showAnswerKey && (
          <li>
            <Button title={t('authorToolbar.answerKey')} onClick={sendToggleAnswerKey}>
              <AnswersIcon />
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}

AuthorToolbar.propTypes = {
  sendEditRequest: PropTypes.func,
  sendToggleComments: PropTypes.func,
  sendToggleAnswerKey: PropTypes.func,
  showEditButton: PropTypes.bool,
  showCommentsButton: PropTypes.bool,
  showAnswerKey: PropTypes.bool,
}

export default AuthorToolbar
