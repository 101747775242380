/* eslint-disable react/no-multi-comp */
import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, Row, Col } from 'reactstrap'
import Breadcrumbs from 'components/Breadcrumbs'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import IframeResizer from 'iframe-resizer-react'
import Spinner from 'components/Spinner'
import { withTheme } from '@mui/styles'
import { Chevron } from '../LXIcons/LXIcons'
import LearningObjectives from '../LearningObjectives/LearningObjectives'
import { useTranslation } from 'react-i18next'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import AuthorToolbar from 'components/AuthorToolbar'
import { useIframeResizerMessages } from 'utils/customHooks'
import { ChevronLeft } from '@mui/icons-material'

const PageLocationBar = ({
  prev,
  next,
  module,
  unit,
  pageTitle,
  pagePosition,
  theme,
  withNavigation = true,
  courseKey,
  showBackToGradebookButton = false,
}) => {
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const level = 'page'
  const moduleLink = bpe.modulePageUrl(
    {
      courseKey: courseKey,
      unitIdentifier: unit.identifier,
      moduleIdentifier: module.identifier,
    },
    true,
  )
  const progress = pagePosition * 100

  return (
    <div className="page-location-bar">
      <Row>
        <Col md="8">
          <Breadcrumbs
            level={level}
            mod={module}
            moduleLink={moduleLink}
            pageTitle={pageTitle}
            light
          />
          <div className="page-location-bar_progress progress d-none d-md-block">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: `${progress}%`,
                backgroundColor: theme.palette.primary.getShade(7),
              }}
            />
          </div>
        </Col>
        <Col>
          {withNavigation && (
            <LXPagination prev={prev} next={next} color={theme.palette.primary.getShade(7)} top />
          )}

          {showBackToGradebookButton && (
            <div className="float-right">
              <a
                href={
                  bpe.exitViewStudentWorkUrl({}, true) +
                  `${window.location.origin}${bpe.gradebookPageUrl({ courseKey })}`
                }
                title={t('nav.vswIconLabel')}
                className="page-location-button-right"
              >
                <ChevronLeft />
                {t('gradebook.backToGradebook')}
              </a>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

PageLocationBar.propTypes = {
  courseKey: PropTypes.string,
  module: PropTypes.object,
  next: PropTypes.string,
  pagePosition: PropTypes.number,
  pageTitle: PropTypes.string,
  prev: PropTypes.string,
  theme: PropTypes.object,
  unit: PropTypes.object,
  withNavigation: PropTypes.bool,
  showBackToGradebookButton: PropTypes.bool,
}

const LXPagination = ({ prev, next, top, bottom, color }) => {
  const hasPrevious = prev
  const hasNext = next
  const navigate = useNavigate()
  const { t } = useTranslation()

  const navigateToPreviousPage = () => navigate(prev)
  const navigateToNextPage = () => navigate(next)

  return (
    <div
      className={classnames({
        'top-pagination': top,
        'bottom-pagination': bottom,
        'workbook-page-navigation': true,
        clearfix: true,
      })}
    >
      <Pagination aria-label={t('pagination.navigation')}>
        <PaginationItem disabled={!hasPrevious}>
          <button
            aria-label={t('pagination.prev')}
            className="page-link prev"
            disabled={!hasPrevious}
            onClick={navigateToPreviousPage}
            type="button"
          >
            <span aria-hidden="true" className="icon">
              <Chevron color={color} />
            </span>
            <span className="sr-only">{t('pagination.prev')}</span>
          </button>
        </PaginationItem>
        <PaginationItem disabled={!hasNext}>
          <button
            aria-label={t('pagination.next')}
            className="page-link next"
            disabled={!hasNext}
            onClick={navigateToNextPage}
            type="button"
          >
            <span aria-hidden="true" className="icon">
              <Chevron color={color} />
            </span>
            <span className="sr-only">{t('pagination.next')}</span>
          </button>
        </PaginationItem>
      </Pagination>
    </div>
  )
}

LXPagination.propTypes = {
  bottom: PropTypes.bool,
  color: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  top: PropTypes.bool,
}

const WorkbookPageLearningObjectives = ({ learningObjectives, theme }) => {
  const { t } = useTranslation()
  const titleStyle = {
    color: theme.palette.primary.getShade(7),
  }

  const los = learningObjectives.map(function (lo) {
    return lo.body
  })

  return (
    <div className="learning-objectives-wrapper">
      <div className="learning-objectives-container">
        <h2 style={titleStyle}>{t('learningObjective.namePlural')}</h2>
        <LearningObjectives los={los} />
      </div>
    </div>
  )
}

WorkbookPageLearningObjectives.propTypes = {
  learningObjectives: PropTypes.array,
  theme: PropTypes.object,
}

const WorkbookPageContent = forwardRef((props, ref) => {
  const { isLoadingWorkbookPageContent, pageSource, iframeResizerOptions, authoringUserActions } =
    props

  const iframeRef = useRef(null)

  const { sendEditRequestToChild, sendToggleAnswerKey, sendToggleComments, sendFocusBack } =
    useIframeResizerMessages(iframeRef)

  const shouldShowAuthoringTools =
    authoringUserActions &&
    (authoringUserActions.userCanComment ||
      authoringUserActions.userCanEditPage ||
      authoringUserActions.showAnswerKey)

  useImperativeHandle(
    ref,
    () => ({
      focusBack() {
        sendFocusBack()
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div
      className={classnames({
        loading: isLoadingWorkbookPageContent,
        'iframe-placeholder': true,
      })}
    >
      <Spinner />
      <IframeResizer
        forwardRef={iframeRef}
        src={pageSource}
        className="workbook-page-iframe"
        allow="microphone *; camera *;"
        allowusermedia="true"
        onMessage={iframeResizerOptions.onMessage}
        onInit={iframeResizerOptions.onInit}
        checkOrigin="false"
      />
      {shouldShowAuthoringTools && (
        <AuthorToolbar
          showEditButton={authoringUserActions.userCanEditPage}
          showCommentsButton={authoringUserActions.userCanComment}
          showAnswerKey={authoringUserActions.showAnswerKey}
          sendEditRequest={sendEditRequestToChild}
          sendToggleAnswerKey={sendToggleAnswerKey}
          sendToggleComments={sendToggleComments}
        />
      )}
    </div>
  )
})

WorkbookPageContent.displayName = 'WorkbookPageContent'

WorkbookPageContent.propTypes = {
  authoringUserActions: PropTypes.object,
  iframeResizerOptions: PropTypes.object,
  isLoadingWorkbookPageContent: PropTypes.bool,
  pageSource: PropTypes.string,
}

export { PageLocationBar, WorkbookPageLearningObjectives, LXPagination }
export const WorkbookPageContentReference = WorkbookPageContent
export const WorkbookPageLearningObjectivesWithTheme = withTheme(WorkbookPageLearningObjectives)
export const PageLocationBarWithTheme = withTheme(PageLocationBar)
