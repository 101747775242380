import contrast from 'get-contrast'

const getMainShadeIndex = (palette, backgroundColor) => {
  let colorIndex = 6
  if (process.env.NODE_ENV === 'production') {
    return colorIndex
  }
  let colorIsAccessible = contrast.isAccessible(palette[colorIndex], backgroundColor)
  while (!colorIsAccessible) {
    colorIndex++
    if (palette[colorIndex] === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `Shades #6 and higher in the color palette do not have a high enough contrast ratio against a background color of ${backgroundColor}.`,
      )
      colorIsAccessible = true
    } else {
      colorIsAccessible = contrast.isAccessible(palette[colorIndex], backgroundColor)
    }
  }
  return colorIndex
}

const createColorPalette = (palette, name) => ({
  get name() {
    return name
  },
  getShade: (index) => palette[index],
  getMainShade: ({ backgroundColor = '#ffffff', offset = 0 } = {}) => {
    let index
    if (backgroundColor === '#ffffff') {
      index = (palette.inflection || getMainShadeIndex(palette, backgroundColor)) + offset
    } else {
      index = getMainShadeIndex(palette, backgroundColor) + offset
    }
    return palette[Math.min(index, Object.keys(palette).length - 1)]
  },
})

export default createColorPalette
