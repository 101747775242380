/* eslint-disable react/no-multi-comp */
import React, { memo, useState, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import classnames from 'classnames'
import { withTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'images/close-icon.svg'
import { getEnrollmentsByUserInput } from 'store/selectors'

const Div = styled('div')(({ theme }) => ({
  '& .MuiTextField-root': {
    marginBottom: 0,
    paddingRight: '5px',

    '& .MuiInputLabel-root': {
      position: 'relative',
      transform: 'none !important',
      color: '#1C1C1C',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 500,
      margin: '0 0 7px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#1C1C1C !important',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#1C1C1C !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root': {
      border: '1px solid rgba(0, 0, 0, 0.43)',
      height: '42px',
      '& > div': {
        color: '#1C1C1C',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      '&::-webkit-search-cancel-button': {
        display: 'none !important',
      },
    },
  },
  '& .MuiButton-root': {
    color: '#FFF',
    height: '42px',
    textTransform: 'none',
    marginLeft: '5px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: '#DADADA',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  '& .list-item': {
    '&:hover': {
      boxShadow: `inset 2px 0px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.legacy.primary.getShade(1),
    },
  },
  '& .input-chip': {
    alignItems: 'center',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap',
    borderRadius: '16px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    padding: '2px 12px 2px 12px',
    margin: '4px 8px',
    backgroundColor: theme.palette.legacy.primary.getShade(1),
    border: `1px solid ${theme.palette.primary.light}`,
    cursor: 'default',

    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
      margin: '0 -5px 0 8px',
      '& svg': {
        height: '16px',
        width: '16px',
      },
    },
  },
}))

const EmailLookup = ({ enrollments, onAdd }) => {
  const { t } = useTranslation()
  const [userList, setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [searchString, setSearchString] = useState('')
  const addBtnRef = useRef(null)

  const onChange = (e) => {
    const inputValue = e.target.value
    setSearchString(inputValue)
    setSelectedUser(null)
    if (inputValue.length < 3) {
      setUserList([])
      return
    }
    const usersFound = getEnrollmentsByUserInput(enrollments, inputValue)
    setUserList(usersFound)
  }

  const onUserSelected = (user) => {
    setSelectedUser(user)
    setSearchString(user.name)
    setUserList([])
    setTimeout(() => {
      addBtnRef.current.focus()
    }, 50)
  }

  const onUserAddClick = () => {
    onAdd(selectedUser)
    setSelectedUser(null)
    setSearchString('')
  }

  const onKeyDown = (e, user) => {
    const isEnterOrSpace = (e.charCode || e.keyCode) === 13 || (e.charCode || e.keyCode) === 32
    const keysToPrevent = isEnterOrSpace || (e.charCode || e.keyCode) === 27 // Escape
    if (keysToPrevent) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (isEnterOrSpace) {
      // Prevent submit on enter or space
      onUserSelected(user)
    }
  }

  const onInputKeyDown = (e) => {
    const isEnter = (e.charCode || e.keyCode) === 13
    if (isEnter && selectedUser) {
      onUserAddClick()
    }
  }

  const removeUserChip = () => {
    setSelectedUser(null)
    setSearchString('')
  }

  const InputChip = forwardRef(() => {
    return (
      <div className="input-chip">
        {`${selectedUser.user.firstName} ${selectedUser.user.lastName}`}
        <button onClick={removeUserChip}>
          <CloseIcon />
        </button>
      </div>
    )
  })

  InputChip.displayName = 'InputChip'

  return (
    <Div className="email-lookup">
      <div className="search">
        <TextField
          id={'email-lookup'}
          value={searchString}
          autoComplete="off"
          type="search"
          label={t('settingsForm.searchStudent')}
          onChange={onChange}
          onKeyDown={onInputKeyDown}
          InputProps={{
            name: 'email-lookup',
            inputComponent: selectedUser ? InputChip : undefined,
          }}
          fullWidth
          margin="dense"
          variant="outlined"
        />

        <Button
          disabled={selectedUser === null}
          onClick={onUserAddClick}
          ref={addBtnRef}
          focusRipple={true}
        >
          {t('settingsForm.addException')}
        </Button>
      </div>
      <div className={classnames('list', { hide: userList.length === 0 })}>
        {userList.map((user) => {
          return (
            <div
              className="list-item"
              key={user.user.id}
              role="button"
              tabIndex="0"
              onKeyDown={(e) => onKeyDown.apply(null, [e, user])}
              onClick={() => onUserSelected.apply(null, [user])}
            >
              <span className="name">{`${user.user.firstName} ${user.user.lastName}`}</span>
              <span className="email">{user.user.email}</span>
            </div>
          )
        })}
      </div>
    </Div>
  )
}

export default memo(withTheme(EmailLookup))

EmailLookup.propTypes = {
  enrollments: PropTypes.array,
  onAdd: PropTypes.func,
}

EmailLookup.defaultProps = {
  enrollments: [],
  onAdd: () => {},
}
