import createColorPalette from './createColorPalette'

const magic = {
  1: '#f2f4f9',
  2: '#d2d9ed',
  3: '#b2bee0',
  4: '#91a3d4',
  5: '#7188c7',
  6: '#5773bf',
  7: '#425ba1',
  8: '#334a88',
  9: '#24386e',
  10: '#002244',
  inflection: 6,
}

export default createColorPalette(magic, 'magic')
