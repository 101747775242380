import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from '@mui/styles'

import AlertMessage from 'components/AlertMessage'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import classnames from 'classnames'
import ScheduleInfo from './ScheduleInfo'
import Completion from './Completion'
import Spinner from 'components/Spinner'
import TOCElement from './TOCElement'
import TOCLabel from './TOCLabel'
import Toggler from './Toggler'
import {
  actionHasErrors,
  getErrorMessage,
  getIsInstructorOrAdmin,
  isActionLoaded,
  isActionLoading,
} from 'store/selectors'
import {
  externalModuleScheduleGet,
  externalModuleSchedulePost,
  externalModuleScheduleRemove,
  resetAction,
  updateExternalModules,
} from 'store/actions'
import { getErrorMessageForAlert } from 'utils/brandedSignupForms'
import { Modal } from 'components'
import { ReactComponent as ActivityDueClock } from 'images/activity-due-clock.svg'
import { ReactComponent as ModuleSettingsIcon } from 'images/icon-gear.svg'
import { ReactComponent as ScheduleSavedIcon } from 'images/menu-module-check.svg'
import { StoreContext } from 'store'

const TOCListElement = ({
  assignmentsLength,
  childListKey,
  childName,
  courseKey,
  isActive,
  isOpen,
  itemType,
  obj,
  onClick,
  setContentNotYetAvailableModal,
  shouldBeHighlighted,
  showCompletion,
  theme,
  tocLevel,
  unitId,
}) => {
  const { api, course, dispatch, externalModules, schedule, userAq } = useContext(StoreContext)

  const justRemovedDueDate = useRef(false)
  const scheduleChanged = useRef(false)
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)

  const [modalOpen, setModalOpen] = useState(false)
  const [scheduleDueDate, setScheduleDueDate] = useState('')
  const [scheduleDueDateTime, setScheduleDueDateTime] = useState('00:00')
  const [showToast, setShowToast] = useState(false)

  const isScheduleGetActionLoaded = isActionLoaded(api, 'SCHEDULE_GET')
  const isScheduleGetActionLoading = isActionLoading(api, 'SCHEDULE_GET')
  const scheduleGetActionErrors = getErrorMessage(api, 'SCHEDULE_GET')
  const scheduleGetActionHasErrors = actionHasErrors(api, 'SCHEDULE_GET')

  const isSchedulePostActionLoaded = isActionLoaded(api, 'SCHEDULE_POST')
  const isSchedulePostActionLoading = isActionLoading(api, 'SCHEDULE_POST')
  const schedulePostActionErrors = getErrorMessage(api, 'SCHEDULE_POST')
  const schedulePostActionHasErrors = actionHasErrors(api, 'SCHEDULE_POST')

  const isScheduleDeleteActionLoaded = isActionLoaded(api, 'SCHEDULE_DELETE')
  const scheduleDeleteActionErrors = getErrorMessage(api, 'SCHEDULE_DELETE')
  const scheduleDeleteActionHasErrors = actionHasErrors(api, 'SCHEDULE_DELETE')

  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)
  const isSaveButtonDisabled =
    isSchedulePostActionLoading ||
    (!justRemovedDueDate.current && (!scheduleDueDate || !scheduleDueDateTime))

  // External Modules (Study Guide)
  const isExternalModule = itemType === 'module' && obj.isExternal
  const shouldDisplayExternalModuleScheduling =
    tocLevel !== 'nav' && isExternalModule && isInstructorOrAdmin
  const hasExternalModuleSchedule =
    shouldDisplayExternalModuleScheduling &&
    ((!scheduleChanged.current && obj?.schedule?.dueDate) ||
      (externalModules &&
        externalModules[obj.moduleId] &&
        externalModules[obj.moduleId]?.schedule?.dueDate))
  const shouldDisplayExternalModuleMetadata =
    tocLevel !== 'nav' && itemType === 'module' && hasExternalModuleSchedule

  const doSchedule = (moduleId) => {
    if (justRemovedDueDate.current && scheduleDueDateTime === '' && scheduleDueDate === '') {
      //save schedule removal
      updateExternalModules({
        dispatch,
        externalModule: { [moduleId]: { dueDate: null } },
      })
      externalModuleScheduleRemove({
        dispatch,
        courseKey,
        moduleId,
      })
    } else {
      const timeToSend = scheduleDueDateTime ? scheduleDueDateTime : '00:00'
      const dueDateAndTime = `${scheduleDueDate}T${timeToSend}`
      const formattedDueDateAndTime = moment.tz(dueDateAndTime, userAq.time_zone).utc().format()
      updateExternalModules({
        dispatch,
        externalModule: { [moduleId]: { dueDate: formattedDueDateAndTime } },
      })
      externalModuleSchedulePost({
        dispatch,
        courseKey,
        moduleId,
        data: {
          due_date: formattedDueDateAndTime,
        },
      })
    }
    justRemovedDueDate.current = false
    scheduleChanged.current = true
  }

  const getSchedule = (event, moduleId) => {
    toggleScheduleModal()
    externalModuleScheduleGet({
      dispatch,
      courseKey,
      moduleId,
    })
  }

  const removeSchedule = () => {
    setScheduleDueDate('')
    setScheduleDueDateTime('')
    justRemovedDueDate.current = true
  }

  // Unit and Modules scheduling
  const isNotAvailable = obj && obj.schedule && !obj.schedule.studentAvailability
  const contentAvailableDate =
    obj?.schedule?.availableDate && moment.tz(obj.schedule.availableDate, userAq.time_zone)
  const shouldShowScheduleData =
    !isNotAvailable &&
    !!contentAvailableDate &&
    !isExternalModule &&
    moment().tz(userAq.time_zone).isSameOrBefore(contentAvailableDate)

  const cleanupScheduleApiStatus = useCallback(() => {
    resetAction({ dispatch, action: 'SCHEDULE_POST' })
    resetAction({ dispatch, action: 'SCHEDULE_GET' })
    resetAction({ dispatch, action: 'SCHEDULE_DELETE' })
  }, [dispatch])

  const items = obj[childListKey] ? obj[childListKey] : []

  let linkHref = ''
  if (itemType === 'unit') {
    linkHref = bpe.courseUnitUrl({ courseKey: courseKey, unitIdentifier: unitId })
  } else if (itemType === 'module') {
    linkHref = bpe.modulePageUrl({
      courseKey: courseKey,
      unitIdentifier: unitId,
      moduleIdentifier: obj.identifier,
    })
  }

  // temp, until we get completion from API
  const [completion, setCompletion] = useState(0)
  useEffect(() => {
    const randomCompletionValues = [0, 100, Math.floor(Math.random() * 100)]
    setCompletion(randomCompletionValues[Math.floor(Math.random() * 3)])
  }, [])

  // Build IDs, needed for accordion a11y
  const getCollapseId = () => {
    switch (itemType) {
      case 'unit':
        return `collapse-${tocLevel}-${obj.unitId}`
      case 'module':
        return `collapse-${tocLevel}-${obj.moduleId}`
      case 'page':
        return `collapse-${tocLevel}-${obj.pageId}`
      default:
        return 'collapse-id'
    }
  }

  // Build metric element, and set label based on number of elements
  const getMetric = (metric) => {
    let elementsLength
    switch (metric) {
      case 'module':
        elementsLength = obj.numModules
        break
      case 'assignment':
        elementsLength = itemType === 'unit' ? obj.numAssignments : assignmentsLength
        break
      case 'activity':
        elementsLength = itemType === 'unit' ? obj.numPages : obj.pages.length
        break
      default:
        return null
    }
    const label = elementsLength !== 1 ? t(`${metric}.namePlural`) : t(`${metric}.name`)
    return (
      <>
        <span className="toc__metrics-value">{elementsLength}</span> {label}
      </>
    )
  }

  const assignmentMetric = itemType === 'unit' ? obj.numAssignments : assignmentsLength

  const toggleScheduleModal = useCallback(() => setModalOpen((modal) => !modal), [])

  const externalModuleDueDate =
    obj.isExternal &&
    ((externalModules &&
      externalModules[obj.moduleId] &&
      externalModules[obj.moduleId]?.schedule?.dueDate) ||
      (obj && obj.schedule && obj.schedule.dueDate))

  const toggleToast = useCallback(() => {
    let closeToastTimeout
    clearTimeout(closeToastTimeout)
    setShowToast(true)
    // Automatically close toast after 2500 ms
    closeToastTimeout = setTimeout(function () {
      setShowToast(false)
    }, 2500)
  }, [])

  useEffect(() => {
    if (!modalOpen && (scheduleDueDate || scheduleDueDateTime)) {
      setScheduleDueDate('')
      setScheduleDueDateTime('')
    }
  }, [modalOpen, scheduleDueDate, scheduleDueDateTime])

  useEffect(() => {
    if (schedule) {
      const dueDate = moment.tz(schedule.dueDate, userAq.time_zone)
      const formattedDueDate = dueDate.format('YYYY-MM-DD')
      const formattedDueDateTime = dueDate.format('HH:mm')
      setScheduleDueDate(formattedDueDate)
      setScheduleDueDateTime(formattedDueDateTime)
    }
  }, [schedule, userAq])

  useEffect(() => {
    if (!modalOpen) return
    if (
      (isSchedulePostActionLoaded && !schedulePostActionHasErrors) ||
      (isScheduleDeleteActionLoaded && !scheduleDeleteActionHasErrors)
    ) {
      toggleScheduleModal()
      cleanupScheduleApiStatus()
      toggleToast()
    }
  }, [
    cleanupScheduleApiStatus,
    isScheduleDeleteActionLoaded,
    isSchedulePostActionLoaded,
    modalOpen,
    scheduleDeleteActionHasErrors,
    schedulePostActionHasErrors,
    toggleScheduleModal,
    toggleToast,
  ])

  useEffect(() => {
    return function cleanup() {
      cleanupScheduleApiStatus()
    }
  }, [cleanupScheduleApiStatus])

  /* External Module Scheduling */
  const modalHeader = t('scheduleModal.modalTitle')
  const modalBody = (
    <>
      {(isScheduleGetActionLoading || isSchedulePostActionLoading) && <Spinner />}
      {isScheduleGetActionLoaded && (
        <>
          {scheduleGetActionHasErrors && scheduleGetActionErrors.status !== 404 && (
            <AlertMessage>{getErrorMessageForAlert(scheduleGetActionErrors)}</AlertMessage>
          )}
          {schedulePostActionHasErrors && (
            <AlertMessage>{getErrorMessageForAlert(schedulePostActionErrors)}</AlertMessage>
          )}
          {scheduleDeleteActionHasErrors && (
            <AlertMessage>{getErrorMessageForAlert(scheduleDeleteActionErrors)}</AlertMessage>
          )}
          <p>{t('scheduleModal.introduction')}</p>
          <Row>
            <Col>
              <FormGroup>
                <Label for="scheduleDueDate">{t('scheduleModal.dateLabel')}</Label>
                <Input
                  id="scheduleDueDate"
                  name="scheduleDueDate"
                  onChange={(event) => setScheduleDueDate(event.target.value)}
                  type="date"
                  value={scheduleDueDate}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="scheduleDueDateTime">{t('scheduleModal.timeLabel')}</Label>
                <Input
                  id="scheduleDueDateTime"
                  name="scheduleDueDateTime"
                  onChange={(event) => setScheduleDueDateTime(event.target.value)}
                  type="time"
                  value={scheduleDueDateTime}
                />
              </FormGroup>
            </Col>
          </Row>
          <p className="text-muted">
            <small>
              {t('scheduleModal.timezone')} {userAq.time_zone}
            </small>
          </p>
        </>
      )}
    </>
  )
  const modalFooter = (
    <>
      {hasExternalModuleSchedule && (
        <Button className="mr-auto" variant="text" onClick={() => removeSchedule(obj.moduleId)}>
          {t('scheduleModal.removeEndDate')}
        </Button>
      )}
      <Button variant="text" onClick={toggleScheduleModal}>
        {t('scheduleModal.cancelButton')}
      </Button>
      <Button disabled={isSaveButtonDisabled} onClick={() => doSchedule(obj.moduleId)}>
        {t('scheduleModal.saveButton')}
      </Button>
    </>
  )

  const getLinkTitle = () => (
    <span className="toc__link-title">
      {(itemType === 'unit' || (itemType === 'module' && obj.isContent)) &&
        obj.ordinal &&
        (tocLevel === 'nav' ? (
          `${obj.courseLabel} ${obj.ordinal}: `
        ) : (
          <>
            <strong>{`${obj.courseLabel} ${obj.ordinal}: `}</strong>{' '}
          </>
        ))}
      {obj.title}
    </span>
  )

  return (
    <li
      data-toc-element-type={itemType}
      data-toc-element-identifier={obj.identifier}
      className={classnames(`toc__list-item--${itemType}`, {
        active: isActive,
        'toc__list-item--not-available': isNotAvailable,
      })}
    >
      {!(tocLevel === 'unit' && itemType === 'unit') &&
        !(tocLevel === 'module' && itemType === 'unit') && (
          <div
            className={`toc__element-wrapper toc__element-wrapper--${itemType}`}
            style={{
              boxShadow:
                shouldBeHighlighted && `inset 5px 0 0 0 ${theme.palette.primary.getShade(5)}`,
            }}
          >
            {showCompletion && itemType === 'module' && tocLevel !== 'module' && (
              <Completion itemType="module" tocLevel={tocLevel} completion={completion} />
            )}
            {/* Link to unit/module */}
            {!(tocLevel === 'module' && itemType === 'module') &&
            (!isNotAvailable || (isNotAvailable && isInstructorOrAdmin)) ? (
              <Link to={linkHref} className={`toc__link toc__link--${itemType}`} title={obj.title}>
                <span>{getLinkTitle()}</span>

                {isNotAvailable && <TOCLabel label={t('toc.notAvailable')} />}

                {shouldShowScheduleData && (
                  <div className="toc__metadata">
                    <div className="toc__metadata-item">
                      <ScheduleInfo tocNode={obj} userAq={userAq} />
                    </div>
                  </div>
                )}

                {shouldDisplayExternalModuleMetadata && (
                  <div className="toc__metadata">
                    <div className="toc__metadata-item">
                      <span className="sr-only">{`${t('assignment.dueDate')}: `}</span>
                      <ActivityDueClock aria-hidden="true" className="toc__metadata-icon" />{' '}
                      {`${t('toc.endDate')} ${moment
                        .tz(externalModuleDueDate, userAq.time_zone)
                        .format(t('toc.dateFormat'))}`}
                    </div>
                  </div>
                )}
              </Link>
            ) : (
              /* In Module Overview pages, module title is not clickable */
              <span className={`toc__link toc__link--${itemType}`}>
                {getLinkTitle()}

                {isNotAvailable && <TOCLabel label={t('toc.notAvailable')} />}

                {shouldShowScheduleData && (
                  <div className="toc__metadata">
                    <div className="toc__metadata-item">
                      <ScheduleInfo tocNode={obj} userAq={userAq} />
                    </div>
                  </div>
                )}
              </span>
            )}
            {/* Box of metrics (number of modules, assignments, activities) */}
            {((tocLevel === 'course' && itemType === 'unit') ||
              (tocLevel === 'unit' && itemType === 'module')) &&
              assignmentMetric > 0 &&
              (!isNotAvailable || (isNotAvailable && isInstructorOrAdmin)) && (
                <ul className="toc__metrics-list">
                  <li className="toc__metrics-list-item">{getMetric('assignment')}</li>
                </ul>
              )}
            {/* Toggler, for expanding/collapsing children list */}
            {!(tocLevel === 'course' && itemType === 'module') &&
              !(tocLevel === 'module' && itemType === 'module') &&
              (!isNotAvailable || (isNotAvailable && isInstructorOrAdmin)) &&
              !isExternalModule && (
                <Toggler
                  isOpen={isOpen}
                  itemType={itemType}
                  onClick={onClick}
                  tocLevel={tocLevel}
                  collapseId={getCollapseId()}
                />
              )}

            {/* External Module */}
            {shouldDisplayExternalModuleScheduling && (
              <button
                onClick={(event) => getSchedule(event, obj.moduleId)}
                className="btn-external-module-schedule"
                type="button"
              >
                <ModuleSettingsIcon aria-hidden="true" />
              </button>
            )}
          </div>
        )}
      {/* Collapsable list of children TOC elements */}
      {!(tocLevel === 'course' && itemType === 'module') &&
        !isExternalModule &&
        (!isNotAvailable || (isNotAvailable && isInstructorOrAdmin)) && (
          <Collapse isOpen={isOpen} id={getCollapseId()}>
            <ul className={`toc__list--${childName}`}>
              {items.map((child) => (
                <TOCElement
                  key={child.identifier}
                  obj={child}
                  itemType={childName}
                  courseKey={courseKey}
                  unitId={unitId}
                  tocLevel={tocLevel}
                  showCompletion={showCompletion}
                  setContentNotYetAvailableModal={setContentNotYetAvailableModal}
                />
              ))}
            </ul>
          </Collapse>
        )}

      {/* External Module Scheduling */}
      <Modal
        modalBody={modalBody}
        modalFooter={modalFooter}
        modalHeader={modalHeader}
        open={modalOpen}
        toggle={toggleScheduleModal}
      />

      {/* Temporary toast implementation */}
      {/* We should implement a toast manager, so they can stack, reopen, etc. */}
      {tocLevel !== 'nav' &&
        ReactDOM.createPortal(
          <Toast isOpen={showToast}>
            <ToastHeader toggle={toggleToast}>
              <ScheduleSavedIcon title={t('scheduleModal.toastTitle')} />{' '}
              {t('scheduleModal.toastTitle')}
            </ToastHeader>
            <ToastBody>{t('scheduleModal.toastBody')}</ToastBody>
          </Toast>,
          document.getElementById('toasts-wrapper'),
        )}
    </li>
  )
}

TOCListElement.propTypes = {
  assignmentsLength: PropTypes.number,
  childListKey: PropTypes.string,
  childName: PropTypes.string,
  courseKey: PropTypes.string,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  itemType: PropTypes.string,
  obj: PropTypes.object,
  onClick: PropTypes.func,
  setContentNotYetAvailableModal: PropTypes.func,
  shouldBeHighlighted: PropTypes.bool,
  showCompletion: PropTypes.bool,
  theme: PropTypes.object,
  tocLevel: PropTypes.string,
  unitId: PropTypes.string,
}

export default React.memo(withTheme(TOCListElement))
