import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import {
  Form,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { ArrowRepeat } from '../LXIcons/LXIcons'
import { resetAssessments } from 'store/actions/courseActions'
import { isActionLoaded, isActionLoading, actionHasErrors } from 'store/selectors'
import { resetAction } from 'store/actions'

const InstructorToolbar = ({ courseKey }) => {
  const { t } = useTranslation()
  const { dispatch, api, resetAssessmentsMessages } = useContext(StoreContext)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showOkModal, setShowOkModal] = useState(false)
  const [inputs, setInputs] = useState({
    resetSummatives: false,
    resetFormatives: false,
  })

  const isResetAssessmentsLoading = isActionLoading(api, 'RESET_ASSESSMENTS')
  const isResetAssessmentsLoaded = isActionLoaded(api, 'RESET_ASSESSMENTS')
  const hasErrors = actionHasErrors(api, 'RESET_ASSESSMENTS')

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isResetAssessmentsLoading) return false
    toggle()
  }

  const handleInputChange = (event) => {
    const { target } = event
    const field = target.id

    setInputs({
      ...inputs,
      [field]: !inputs[field],
    })
  }

  const isSubmitButtonDisabled = () => {
    return (!inputs.resetFormatives && !inputs.resetSummatives) || isResetAssessmentsLoading
  }

  const toggle = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal)
  }, [showConfirmationModal])

  const submitForm = () => {
    const { resetFormatives, resetSummatives } = inputs

    resetAssessments({
      dispatch,
      courseKey: courseKey,
      data: JSON.stringify({ formatives: resetFormatives, summatives: resetSummatives }),
    })
  }

  const cancel = useCallback(() => {
    setInputs({
      resetFormatives: false,
      resetSummatives: false,
    })
    toggle()
  }, [toggle])

  const toggleOkModal = useCallback(() => setShowOkModal(!showOkModal), [showOkModal])

  useEffect(() => {
    if (isResetAssessmentsLoaded && !hasErrors) {
      cancel()
      toggleOkModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetAssessmentsLoaded, hasErrors, resetAssessmentsMessages])

  useEffect(() => {
    return function cleanup() {
      resetAction({ dispatch, action: 'RESET_ASSESSMENTS' })
    }
  }, [dispatch])

  return (
    <Form className="instructor-toolbar d-flex mt-5 justify-content-end" onSubmit={handleSubmit}>
      <FormGroup check inline id="instructorToolbarTooltip1">
        <Label check>
          <Input
            type="checkbox"
            id="resetFormatives"
            checked={inputs.resetFormatives}
            onChange={handleInputChange}
          />{' '}
          {t('instructorToolbar.formativesCheckboxLabel')}
        </Label>
      </FormGroup>
      <UncontrolledTooltip
        placement="top"
        target="instructorToolbarTooltip1"
        autohide={false}
        className="text-left"
      >
        {t('instructorToolbar.tooltip1.formativesDescription')}
      </UncontrolledTooltip>
      <FormGroup check inline id="instructorToolbarTooltip2">
        <Label check>
          <Input
            type="checkbox"
            id="resetSummatives"
            checked={inputs.resetSummatives}
            onChange={handleInputChange}
          />{' '}
          {t('instructorToolbar.summativesCheckboxLabel')}
        </Label>
      </FormGroup>
      <UncontrolledTooltip
        placement="top"
        target="instructorToolbarTooltip2"
        autohide={false}
        className="text-left"
      >
        {t('instructorToolbar.tooltip1.summativesDescription')}
      </UncontrolledTooltip>
      <Button
        type="submit"
        id="resetAssessments"
        className="reset-assessments-button"
        disabled={isSubmitButtonDisabled()}
      >
        <ArrowRepeat className="mr-1" /> {t('instructorToolbar.clearButtonLabel')}
      </Button>

      <Modal isOpen={showConfirmationModal} toggle={toggle} className="instructor-toolbar-modal">
        <ModalHeader toggle={cancel} />
        <ModalBody>
          <p>{t('instructorToolbar.modalBody')}</p>
          <p>{t('instructorToolbar.modalBodyWarning')}</p>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <Button variant="text" className="mr-2" onClick={cancel}>
              {t('instructorToolbar.modalCancel')}
            </Button>
            <Button onClick={submitForm} disabled={isSubmitButtonDisabled()}>
              {t('instructorToolbar.clearButtonLabel')}
            </Button>{' '}
          </div>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showOkModal} toggle={toggleOkModal} className="instructor-toolbar-modal">
        <ModalBody>{t('instructorToolbar.modalOkBody')}</ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <Button onClick={toggleOkModal}>{t('instructorToolbar.modalOk')}</Button>
          </div>
        </ModalFooter>
      </Modal>
    </Form>
  )
}

InstructorToolbar.propTypes = {
  courseKey: PropTypes.string,
}

export default InstructorToolbar
