import createColorPalette from './createColorPalette'

const cherry = {
  1: '#f54250',
  2: '#cc313d',
  3: '#510006',
}

const createCherryColorPalette = () => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: The cherry color palette has been deprecated and will be removed in a future release. The 'red' palette in colors/red.js will be used in the future.",
    )
  }

  return createColorPalette(cherry, 'cherry')
}

export default createCherryColorPalette()
