import createColorPalette from './createColorPalette'

const eggplant = {
  1: '#f8f5fa',
  2: '#ddcbe9',
  3: '#c4a4d9',
  4: '#b389d0',
  5: '#a775c9',
  6: '#945eb9',
  7: '#794d96',
  8: '#5e3c75',
  9: '#3f284f',
  10: '#2d1c38',
  inflection: 6,
}

export default createColorPalette(eggplant, 'eggplant')
