import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from '@mui/styles'
import FormController from './FormController'
import FormDescription from './FormDescription'
import BottomActionsField from './FormModules/BottomActionsField'
import { generateAssignmentScheduleFormData } from './assignmentSchedule'
import { resolveMultipleValuesMap } from './smartFormHelpers'
import { formValidationFunction } from './formValidation'
import { formSubmitFunction } from './formActions'
import { StoreContext } from 'store'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import AssessmentSettingsDialog from 'components/AssessmentSettingsDialog'
import CurrentTimezone from 'components/CurrentTimezone'
import { DEFAULT_DRAWER_ID } from 'utils/constants'
import { log } from 'utils/log'

const SmartForm = ({ theme, courseKey, isAssignmentSplashPage }) => {
  const { t } = useTranslation()
  const { dispatch, userAq } = useContext(StoreContext)
  const { assignmentSelection, assignmentTypes, confirmationDialogMode, dispatchSettingsAction } =
    useSettingsContext()
  const { formInitialValues, formModules } = generateAssignmentScheduleFormData(assignmentSelection)

  const userTimezone = userAq?.time_zone || 'America/New_York'

  const validationFunction = (values) =>
    formValidationFunction.apply(null, [values, t, userTimezone])

  const submitFunction = (values) =>
    formSubmitFunction.apply(null, [
      values,
      formInitialValues,
      assignmentSelection,
      userTimezone,
      courseKey,
      dispatch,
      dispatchSettingsAction,
      assignmentTypes,
      isAssignmentSplashPage,
    ])

  const multipleValuesMap = useMemo(
    () => resolveMultipleValuesMap(formInitialValues),
    [formInitialValues],
  )

  log('formInitialValues', formInitialValues)
  log('SELECTION', assignmentSelection, formInitialValues)

  return (
    <>
      <FormController
        initialValues={formInitialValues}
        onSubmit={submitFunction}
        validate={validationFunction}
        className="smart-form"
        validateOnChange={false}
        validateOnBlur={false}
        timezone={userTimezone}
        multipleValuesMap={multipleValuesMap}
      >
        <FormDescription courseKey={courseKey} isAssignmentSplashPage={isAssignmentSplashPage} />
        {formModules.map((FormModule, idx) => {
          return <FormModule theme={theme} key={idx} />
        })}
        {isAssignmentSplashPage && (
          <CurrentTimezone showAsterisk timezone={userAq?.time_zone} catchLinkClick={true} />
        )}
        <BottomActionsField drawerId={DEFAULT_DRAWER_ID} />
      </FormController>
      <AssessmentSettingsDialog mode={confirmationDialogMode} />
    </>
  )
}

export default withTheme(SmartForm)

SmartForm.propTypes = {
  /**
   * Course key
   */
  courseKey: PropTypes.string,
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * Boolean for whether drawer is on assignment splash page or not
   */
  isAssignmentSplashPage: PropTypes.bool,
}

SmartForm.defaultProps = {
  theme: {},
  courseKey: '',
  isAssignmentSplashPage: false,
}
