import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, FormGroup, FormFeedback, Label, Input, Alert } from 'reactstrap'
import Button from '@mui/material/Button'
import { changePassword, getAccountCenterUrl, resetAction, unsetProfile } from 'store/actions'
import { StoreContext } from 'store'
import { isActionLoaded, actionHasErrors, isLoading } from 'store/selectors'
import { useFormHook } from 'utils/customHooks'
import { isEmptyObject } from 'utils/functional'
import Spinner from 'components/Spinner'
import ReCaptcha from 'components/ReCaptcha'
import { withCurrentUser } from 'hoc'

const ChangePasswordForm = ({ currentUser }) => {
  const { t } = useTranslation()
  const { api, profile, dispatch, userAq, accountCenterUrl } = useContext(StoreContext)
  const [alert, setAlert] = useState({ show: false, type: 'primary', message: '' })
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [shouldResetCaptcha, setShouldResetCaptcha] = useState(false)
  const [profileLinkClicked, setProfileLinkClicked] = useState(false)

  const getAccountCenterUrlActionLoaded = isActionLoaded(api, 'ACCOUNT_CENTER_URL_GET')
  const getAccountCenterUrlActionHasErrors = actionHasErrors(api, 'ACCOUNT_CENTER_URL_GET')

  const shouldLoadAuthIframe =
    getAccountCenterUrlActionLoaded &&
    !getAccountCenterUrlActionHasErrors &&
    accountCenterUrl &&
    profileLinkClicked

  const handleLinkToAccountCenter = (e) => {
    e.preventDefault()
    setProfileLinkClicked(true)
    getAccountCenterUrl({ dispatch })
  }

  const linkOpenedCallback = useCallback(() => {
    setProfileLinkClicked(false)
    resetAction({ dispatch, action: 'ACCOUNT_CENTER_URL_GET' })
  }, [dispatch])

  useEffect(() => {
    if (shouldLoadAuthIframe) {
      window.open(accountCenterUrl, '_blank')
      linkOpenedCallback()
    }
  }, [shouldLoadAuthIframe, linkOpenedCallback, accountCenterUrl])

  useEffect(() => {
    if (getAccountCenterUrlActionHasErrors) {
      window.open(currentUser.bookshelf_user_profile_url, '_blank')
      linkOpenedCallback()
    }
  }, [getAccountCenterUrlActionHasErrors, linkOpenedCallback, currentUser])

  const hasChangePasswordErrors = actionHasErrors(api, 'PROFILE_CHANGE_PASSWORD')

  const initialState = {
    currentPassword: '',
    newPassword: '',
    passwordConfirm: '',
    captcha: '',
  }

  const resetFormCallback = () => {
    setShouldResetCaptcha(true)
  }

  const resetCaptchaCallback = () => {
    setShouldResetCaptcha(false)
  }

  const validateForm = (inputs) => {
    let partialErrors = {}

    // Validate required fields (all inputs)
    Object.keys(inputs).map((fieldName) => {
      if (!inputs[fieldName]) {
        return (partialErrors[fieldName] = t('errors.isRequired'))
      }
      return false
    })

    if (!isEmptyObject(partialErrors)) {
      return partialErrors
    }

    return {}
  }

  const submit = (inputs) => {
    const { currentPassword, newPassword, passwordConfirm, captcha } = inputs
    setWasSubmitted(false)

    changePassword({
      dispatch,
      data: JSON.stringify({ currentPassword, newPassword, passwordConfirm, captcha }),
    })
  }

  const { inputs, handleInputChange, handleSubmit, errors, setErrors, resetForm } = useFormHook(
    initialState,
    submit,
    validateForm,
  )

  useEffect(() => {
    if (hasChangePasswordErrors) {
      setShouldResetCaptcha(true)
    }
  }, [hasChangePasswordErrors, setShouldResetCaptcha])

  useEffect(() => {
    return function cleanup() {
      unsetProfile({ dispatch })
      resetAction({ dispatch, action: 'PROFILE_CHANGE_PASSWORD' })
    }
  }, [dispatch])

  if (
    profile &&
    profile.data &&
    profile.hasErrors &&
    profile.isPasswordChangedReady &&
    !errors.ready
  ) {
    const errors = {}
    Object.keys(profile.data).forEach((x) => {
      errors[x] = profile.data[x][0]
    })
    setErrors({ ...errors, ready: true })
    return
  }

  if (
    !wasSubmitted &&
    profile &&
    profile.isPasswordChangedReady &&
    !profile.hasErrors &&
    profile.success
  ) {
    setAlert({ show: true, type: 'success', message: t('profile.changePassword.passwordChanged') })
    setWasSubmitted(true)
    resetForm(resetFormCallback)
  }

  const isApiLoading = isLoading(api)

  return (
    <>
      {isApiLoading && <Spinner position="float-center" />}

      {!currentUser.lti && currentUser.is_bookshelf_user && (
        <>
          <p>{t(`profile.changePassword.bookshelfUserAlert`)}</p>
          <div>
            <a
              href="javascript;:"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkToAccountCenter}
              disabled={profileLinkClicked}
            >
              {t(`profile.changePassword.linkToVSAccountCenter`)}
            </a>
          </div>
        </>
      )}
      {currentUser.lti && <p>{t(`profile.changePassword.lmsAlert`)}</p>}
      {!currentUser.lti && !currentUser.is_bookshelf_user && (
        <>
          <p className="sidebar-layout__form-description">
            {t(`passwordProfiles.rules.${userAq.password_profile.toLowerCase()}`)}
          </p>
          {alert.show && <Alert color={alert.type}>{alert.message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="currentPassword">{t('profile.changePassword.currentPassword')}</Label>
              <Input
                type="password"
                name="currentPassword"
                id="currentPassword"
                onChange={handleInputChange}
                value={inputs.currentPassword}
                invalid={!!errors.currentPassword}
                disabled={isApiLoading}
              />
              <FormFeedback>{errors.currentPassword}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="newPassword">{t('profile.changePassword.newPassword')}</Label>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                onChange={handleInputChange}
                value={inputs.newPassword}
                invalid={!!errors.newPassword}
                disabled={isApiLoading}
              />
              <FormFeedback>{errors.newPassword}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="passwordConfirm">{t('profile.changePassword.passwordConfirm')}</Label>
              <Input
                type="password"
                name="passwordConfirm"
                id="passwordConfirm"
                onChange={handleInputChange}
                value={inputs.passwordConfirm}
                invalid={!!errors.passwordConfirm}
                disabled={isApiLoading}
              />
              <FormFeedback>{errors.passwordConfirm}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input type="text" invalid={!!errors.captcha} style={{ display: 'none' }} />
              <ReCaptcha
                onChange={handleInputChange}
                resetCallback={resetCaptchaCallback}
                shouldResetCaptcha={shouldResetCaptcha}
              />
              <FormFeedback>{errors.captcha}</FormFeedback>
            </FormGroup>
            <Button type="submit" disabled={isApiLoading}>
              {t('profile.changePassword.changePasswordButton')}
            </Button>
          </Form>
        </>
      )}
    </>
  )
}

ChangePasswordForm.propTypes = {
  currentUser: PropTypes.object,
}

export default withCurrentUser(ChangePasswordForm)
