import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from '@mui/styles'
import FormController from './FormController'
import FormDescription from './FormDescription'
import BottomActionsField from './FormModules/BottomActionsField'
import { SmartFormModules } from './SmartFormModules'
import { generateUnitsAndModulesFormData } from './assignmentSchedule'
import { formValidationFunction } from './formValidation'
import { formSubmitUnitOrModuleFunction } from './formActions'
import { StoreContext } from 'store'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import AssessmentSettingsDialog from 'components/AssessmentSettingsDialog'
import CurrentTimezone from 'components/CurrentTimezone'
import { UNITS_AND_MODULES_DRAWER_ID } from 'utils/constants'
import { log } from 'utils/log'

const UnitsAndModulesForm = ({ theme, courseKey, isAssignmentSplashPage }) => {
  const { t } = useTranslation()
  const { dispatch, userAq } = useContext(StoreContext)
  const { unitOrModuleSelection, assignmentTypes, confirmationDialogMode, dispatchSettingsAction } =
    useSettingsContext()

  const { formInitialValues } = generateUnitsAndModulesFormData(unitOrModuleSelection)

  const userTimezone = userAq?.time_zone || 'America/New_York'

  const validationFunction = (values) =>
    formValidationFunction.apply(null, [values, t, userTimezone])

  const submitFunction = (values) =>
    formSubmitUnitOrModuleFunction.apply(null, [
      values,
      formInitialValues,
      unitOrModuleSelection,
      userTimezone,
      courseKey,
      dispatch,
      dispatchSettingsAction,
      assignmentTypes,
      isAssignmentSplashPage,
    ])

  log('Unit or Module: ', unitOrModuleSelection)

  const { StudentAvailabilityCheckField, StartDateAndTimeField } = SmartFormModules

  return (
    <>
      <FormController
        initialValues={formInitialValues}
        onSubmit={submitFunction}
        validate={validationFunction}
        className="smart-form"
        validateOnChange={false}
        validateOnBlur={false}
        timezone={userTimezone}
      >
        <FormDescription
          courseKey={courseKey}
          isAssignmentSplashPage={isAssignmentSplashPage}
          unitOrModuleTitle={unitOrModuleSelection?.title}
        />
        <StudentAvailabilityCheckField theme={theme} />
        <StartDateAndTimeField theme={theme} />
        {isAssignmentSplashPage && (
          <CurrentTimezone timezone={userAq?.time_zone} catchLinkClick={true} />
        )}
        <BottomActionsField drawerId={UNITS_AND_MODULES_DRAWER_ID} />
      </FormController>
      <AssessmentSettingsDialog mode={confirmationDialogMode} />
    </>
  )
}

export default withTheme(UnitsAndModulesForm)

UnitsAndModulesForm.propTypes = {
  /**
   * Course key
   */
  courseKey: PropTypes.string,
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * Boolean for whether drawer is on assignment splash page or not
   */
  isAssignmentSplashPage: PropTypes.bool,
}

UnitsAndModulesForm.defaultProps = {
  theme: {},
  courseKey: '',
  isAssignmentSplashPage: false,
}
