import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { withTheme } from '@mui/styles'

const FormController = ({
  onSubmit,
  validate,
  initialValues,
  multipleValuesMap,
  children,
  className,
  validateOnChange,
  validateOnBlur,
  timezone,
  theme,
  showEmptySelectLabel,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      enableReinitialize={true}
    >
      <Form className={`form-controller ${className}`} autoComplete="off">
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              theme,
              timezone,
              multipleValuesMap,
              showEmptySelectLabel,
            })
          }
          return child
        })}
      </Form>
    </Formik>
  )
}

export default withTheme(FormController)

FormController.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  initialValues: PropTypes.shape({}),
  multipleValuesMap: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  showEmptySelectLabel: PropTypes.bool,
  theme: PropTypes.shape({}),
  timezone: PropTypes.string,
  validate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
}

FormController.defaultProps = {
  children: [],
  className: '',
  initialValues: {},
  multipleValuesMap: {},
  onSubmit: () => {},
  showEmptySelectLabel: false,
  theme: {},
  timezone: 'America/New_York',
  validate: () => {},
  validateOnBlur: false,
  validateOnChange: false,
}
