import createColorPalette from './createColorPalette'

const blue = {
  1: '#eef7ff',
  2: '#deedfd',
  3: '#bddbf4',
  4: '#84baeb',
  5: '#3b8fde',
  6: '#1176d4',
  7: '#0d66b8',
  8: '#09549c',
  9: '#0a4276',
  10: '#072d52',
  inflection: 6,
}

export default createColorPalette(blue, 'blue')
