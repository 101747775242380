import React, { memo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import AlertMessage from 'components/AlertMessage'
import { ExclamationCircleOutline } from 'components/LXIcons/LXIcons'
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg'
import { StoreContext } from 'store'
import EmailLookup from './EmailLookup'
import Drawer from 'components/Drawer'
import ExceptionSmartForm from './ExceptionSmartForm'
import AssessmentSettingsDialog from 'components/AssessmentSettingsDialog'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import {
  closeConfirmationDialog,
  openEditExceptionsSettingsDrawer,
  setConfirmationDialogMode,
} from 'pages/Settings/store/actions'
import { EDIT_EXCEPTIONS_DRAWER_ID } from 'utils/constants'
import { actionHasErrors, getExceptionsByAssignmentIdentifier } from 'store/selectors'
import {
  deleteAssignmentException,
  getAssignmentsUnitsAndModules,
  getAssignmentsExceptions,
} from 'store/actions'
import { fetchEmptyException, closeDrawer } from './smartFormHelpers'
import { log } from 'utils/log'
import { styled } from '@mui/material/styles'

const Div = styled('div')(({ theme }) => ({
  '& .exception-item': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    borderRadius: '4px',
    margin: '8px 0',
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      padding: 0,
      margin: 0,
      textTransform: 'capitalize',
    },
    '&:hover': {
      boxShadow: `inset 3px 0px ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.legacy.primary.getShade(1),
      borderRadius: '0 4px 4px 0',
    },
  },
  '& .edit-btn': {
    width: '100%',
    textAlign: 'left',
    border: 'none',
    backgroundColor: 'transparent',
    padding: '16px 16px 16px 24px',
  },
  '& .delete-btn': {
    border: 'none',
    backgroundColor: 'transparent',
    padding: '16px 24px 16px 16px',
    '& svg': {
      height: '16px',
      width: '16px',
      transform: 'translateY(-1px)',
      fill: theme.palette.primary.main,
    },
  },
}))

const Exceptions = ({ courseKey }) => {
  const { t } = useTranslation()
  const [currentUserException, setCurrentUserException] = useState(null)

  const {
    assignmentSelection,
    assignmentTypes,
    confirmationDialogMode,
    dispatchSettingsAction,
    isSettingsDrawerOpen,
    isFormDirty,
  } = useSettingsContext()
  const { api, assignmentsExceptions, dispatch, enrollments } = useContext(StoreContext)

  const exceptions =
    getExceptionsByAssignmentIdentifier(
      assignmentsExceptions,
      assignmentSelection[0]?.identifier,
    ) || []

  const findExceptionByUser = (user) => {
    return exceptions.filter((ex) => ex.user.email === user.user.email)[0]
  }

  log('EXCEPTIONS', exceptions)

  const addUserException = (user) => {
    let currentException = user
    const userException = findExceptionByUser(user)
    if (userException) {
      currentException = { ...user, exception: userException }
    } else {
      currentException = { ...user, exception: fetchEmptyException() }
    }

    setCurrentUserException(currentException)
    openEditExceptionsSettingsDrawer({ dispatchSettingsAction })
  }

  const handleErrorsDeletingExceptions = (dispatchSettingsAction) => {
    closeConfirmationDialog({ dispatchSettingsAction })
    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'error',
        bodyI18n: 'assignmentsSettings.errors.deletingExceptions',
      },
    })
  }

  const removeUserException = (e, userException) => {
    e.preventDefault()
    const applyChangesFn = async () => {
      await deleteAssignmentException({
        dispatch,
        courseKey,
        data: {
          uuid: userException.uuid,
        },
        returnRejectedPromise: true,
      })
      await getAssignmentsExceptions({
        dispatch,
        courseKey,
        returnRejectedPromise: true,
      }).catch(() => closeConfirmationDialog({ dispatchSettingsAction }))
      closeConfirmationDialog({ dispatchSettingsAction })

      await getAssignmentsUnitsAndModules({
        dispatch,
        courseKey,
        purposeTypes: assignmentTypes ? Object.keys(assignmentTypes).join() : null,
        returnRejectedPromise: true,
      })
    }

    const applyFnWrapper = () =>
      applyChangesFn().catch(() => handleErrorsDeletingExceptions(dispatchSettingsAction))

    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'delete',
        callback: applyFnWrapper,
      },
    })
  }

  const editUserException = (e, user) => {
    e.preventDefault()
    e.stopPropagation()
    const userException = findExceptionByUser(user)
    const currentException = { ...user, exception: userException }
    setCurrentUserException(currentException)
    openEditExceptionsSettingsDrawer({ dispatchSettingsAction })
  }

  const fetchUserName = (user) => {
    let userName = ''
    if (user && user.user) {
      userName = `${user.user.firstName} ${user.user.lastName}`
    }
    return userName
  }

  const generateExceptionsDisplay = () => {
    return exceptions.map((user) => {
      return (
        <div className="exception-item" key={user.uuid}>
          <button className="edit-btn" onClick={(e) => editUserException.apply(null, [e, user])}>
            <h3>{fetchUserName(user)}</h3>
          </button>
          <button
            className="delete-btn"
            onClick={(e) => removeUserException.apply(null, [e, user])}
          >
            <DeleteIcon />
          </button>
        </div>
      )
    })
  }

  const hasErrorsGettingEnrollments = actionHasErrors(api, 'ENROLLMENTS_GET')
  const hasErrorsGettingExceptions = actionHasErrors(api, 'ASSIGNMENTS_EXCEPTIONS_GET')
  const hasErrors = hasErrorsGettingEnrollments || hasErrorsGettingExceptions

  return (
    <>
      <Div className="exceptions">
        {hasErrors ? (
          <AlertMessage color="danger" icon={<ExclamationCircleOutline />}>
            {t('assignmentsSettings.errors.gettingExceptions')}
          </AlertMessage>
        ) : (
          <div>
            <div className="form-module exceptions-header">
              <p>{t('settingsForm.exceptionsDescription')}</p>
            </div>
            <div className="form-module">
              <EmailLookup enrollments={enrollments} onAdd={addUserException} />
            </div>
            <div className="form-module">{generateExceptionsDisplay()}</div>
          </div>
        )}
      </Div>
      <Drawer
        title={`${t('settingsDrawer.exceptionForTitle')} ${fetchUserName(currentUserException)}`}
        open={isSettingsDrawerOpen[EDIT_EXCEPTIONS_DRAWER_ID]}
        drawerId={EDIT_EXCEPTIONS_DRAWER_ID}
        showBackBtn
        closeCallback={() => {
          closeDrawer({
            dispatchSettingsAction,
            drawerId: EDIT_EXCEPTIONS_DRAWER_ID,
            isFormDirty,
          })
        }}
      >
        <ExceptionSmartForm userException={currentUserException} courseKey={courseKey} />
      </Drawer>
      <AssessmentSettingsDialog mode={confirmationDialogMode} />
    </>
  )
}

export default memo(withTheme(Exceptions))

Exceptions.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  courseKey: PropTypes.string,
}

Exceptions.defaultProps = {
  theme: {},
  courseKey: '',
}
