import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import Button from '@mui/material/Button'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import OpenInNew from '@mui/icons-material/OpenInNew'
import moment from 'moment'
import { getIsInstructorByEmail } from 'store/selectors/courseSelectors'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const CourseInfo = ({
  course,
  closeDrawer,
  openCloneDrawer,
  toggleInstructionsModal,
  userEmail,
}) => {
  const { t, i18n } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)

  const showCopyButton = course ? getIsInstructorByEmail(userEmail, course.instructors) : false

  const { courseKey, enableDashboard } = course

  const onClose = () => {
    closeDrawer()
  }

  const onOpenCloneDrawer = () => {
    openCloneDrawer(course)
  }

  const handleToggleInstructionsModal = () => {
    toggleInstructionsModal(course)
  }

  return (
    <>
      <div className="drawer-body">
        <Table borderless responsive>
          <tbody>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.title')}</th>
              <td>{course.title}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.courseKey')}</th>
              <td>{courseKey}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.courseActions.heading')}</th>
              <td>
                {enableDashboard && (
                  <>
                    <a
                      href={bpe.dashboardPageUrl({ courseKey }, true)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="external-link"
                    >
                      {t('courses.courseInfoDrawer.courseActions.learningDashboard')}
                      <OpenInNew />
                    </a>
                    <br />
                  </>
                )}
                <a
                  href={bpe.gradebookPageUrl({ courseKey })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="external-link"
                >
                  {t('courses.courseInfoDrawer.courseActions.courseGradebook')}
                  <OpenInNew />
                </a>
                <br />
                <a
                  href={bpe.courseSettingsPageUrl({ courseKey }, true)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="external-link"
                >
                  {t('courses.courseInfoDrawer.courseActions.courseSettings')}
                  <OpenInNew />
                </a>
                <br />
                <a href={bpe.studentWorkPageUrl({ courseKey })} className="external-link">
                  {t('courses.courseInfoDrawer.courseActions.viewStudentWork')}
                  <OpenInNew />
                </a>
              </td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.startDate')}</th>
              <td>{course.startDate && moment(course.startDate).format('l')}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.endDate')}</th>
              <td>{course.endDate && moment(course.endDate).format('l')}</td>
            </tr>
            <tr>
              <th scope="row">{t('courses.courseInfoDrawer.instructorEmail')}</th>
              <td>
                {course.instructors &&
                  course.instructors.map((instructor) => (
                    <span key={instructor.id}>
                      {instructor.email}
                      <br />
                    </span>
                  ))}
              </td>
            </tr>
          </tbody>
        </Table>

        {course && course.customer && course.customer.enableCourseCopy && (
          <Button variant="outlined" onClick={handleToggleInstructionsModal}>
            <VerticalAlignBottomIcon className="button-icon" />
            {t('courses.courseInfoDrawer.download')}
          </Button>
        )}
      </div>
      <div className="bottom-actions text-right">
        {showCopyButton && course && course.customer && course.customer.enableCourseCopy && (
          <Button variant="outlined" type="button" onClick={onOpenCloneDrawer}>
            <FileCopyOutlinedIcon className="button-icon" />
            {t('courses.courseInfoDrawer.clone')}
          </Button>
        )}
        <Button onClick={onClose}>{t('generics.close')}</Button>
      </div>
    </>
  )
}

CourseInfo.propTypes = {
  course: PropTypes.object,
  closeDrawer: PropTypes.func,
  openCloneDrawer: PropTypes.func,
  toggleInstructionsModal: PropTypes.func,
  userEmail: PropTypes.string,
}

export default CourseInfo
