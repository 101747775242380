import createColorPalette from './createColorPalette'

const sangria = {
  1: '#fdf2f5',
  2: '#fbcada',
  3: '#f8a2bf',
  4: '#f57aa3',
  5: '#ed3e79',
  6: '#d81e5d',
  7: '#bf1b52',
  8: '#a61747',
  9: '#8c143c',
  10: '#610e29',
  inflection: 6,
}

export default createColorPalette(sangria, 'sangria')
