import createColorPalette from './createColorPalette'

const grass = {
  1: '#21a921',
  2: '#0a870a',
}

const createGrassColorPalette = () => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: The grass color palette has been deprecated and will be removed in a future release. The 'green' palette in colors/green.js will be used in the future.",
    )
  }

  return createColorPalette(grass, 'grass')
}

export default createGrassColorPalette()
