import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from 'components'
import Button from '@mui/material/Button'
import { copyRichText, completeUrl } from 'utils/functional'

const CourseRegisterInstructions = ({ course, showInstructionsModal, toggleInstructionsModal }) => {
  const { t } = useTranslation()
  const [instructionsCopied, setInstructionsCopied] = useState(false)

  if (!course) return <></>
  const { title, courseKey, courseTitle, customer } = course

  if (!customer) return <></>
  const brandedUrl =
    customer && customer.enrollmentInstructionsUrl
      ? customer.enrollmentInstructionsUrl
      : window.location.origin
  const customerSupportUrl = customer ? completeUrl(customer.supportUrl) : ''

  const copyInstructions = () => {
    let closeAlertTimeout
    clearTimeout(closeAlertTimeout)
    copyRichText(`instructions-${courseKey}`)

    setInstructionsCopied(true)
    closeAlertTimeout = setTimeout(function () {
      setInstructionsCopied(false)
    }, 2500)
    return false
  }

  const ct = title ? title : courseTitle

  const modalHeader = <>{t('courses.instructionsModal.header')}</>

  const modalBody = (
    <>
      <div className="instructions" id={`instructions-${courseKey}`}>
        <p>
          <Trans i18nKey="courses.instructionsModal.headline1.title" ct={ct}>
            <strong>To register for {{ ct }}</strong>:
          </Trans>
        </p>
        <ol>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline1.bullet1" brandedUrl={brandedUrl}>
              Go to <a href={brandedUrl}>{{ brandedUrl }}</a>
            </Trans>
          </li>
          <li>{t('courses.instructionsModal.headline1.bullet2')}</li>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline1.bullet3">
              On the Your Courses page, click <strong>Add a Course</strong>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline1.bullet4" courseKey={courseKey}>
              Enter the following course key: <strong>{{ courseKey }}</strong> and click{' '}
              <strong>Submit</strong>
            </Trans>
          </li>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline1.bullet5">
              If payment is required, you have the following <strong>Payment Options:</strong>
            </Trans>
            <ul>
              <li>
                <Trans i18nKey="courses.instructionsModal.headline1.bullet51">
                  <strong>Trial access may be available</strong> for your course. The course will
                  become unavailable, and you will be prompted for payment or access code redemption
                  when the trial date expires, but you have full access to the course and its
                  content up to that date.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="courses.instructionsModal.headline1.bullet52">
                  <strong>Buy access</strong> using a credit card or PayPal account. After
                  completing the payment process, you will be redirected to your course home page.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="courses.instructionsModal.headline1.bullet53">
                  Or enter the <strong>access code</strong> that came with your textbook or was
                  purchased separately from the bookstore. After redeeming your access code, you
                  will be redirected to your course home page.
                </Trans>
              </li>
            </ul>
          </li>
        </ol>
        <p>
          <strong>{t('courses.instructionsModal.headline2.title')}</strong>
        </p>
        <ol>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline2.bullet1" brandedUrl={brandedUrl}>
              Go to <a href={brandedUrl}>{{ brandedUrl }}</a>
            </Trans>
          </li>
          <li>{t('courses.instructionsModal.headline2.bullet2')}</li>
          <li>
            <Trans i18nKey="courses.instructionsModal.headline2.bullet3" ct={ct}>
              Select <strong>{{ ct }}</strong> from My Courses.
            </Trans>
          </li>
        </ol>
        <p>
          <Trans i18nKey="courses.instructionsModal.needHelp">
            <strong>Need help?</strong> <a href={customerSupportUrl}>Click here for support </a>
          </Trans>
          {` `}
          <span className="sr-only sr-only-focusable">{customerSupportUrl}</span>
        </p>
      </div>
      <Button onClick={copyInstructions}>
        {t('courses.instructionsModal.copyInstructionsButton')}
      </Button>
      {instructionsCopied && (
        <span className="clipboard-alert small">Instructions copied to clipboard</span>
      )}
    </>
  )

  if (!customer) return <></>

  return (
    <Modal
      modalBody={modalBody}
      modalHeader={modalHeader}
      open={showInstructionsModal}
      toggle={toggleInstructionsModal}
      className="instructions-modal full-width"
    />
  )
}

CourseRegisterInstructions.propTypes = {
  course: PropTypes.object,
  showInstructionsModal: PropTypes.bool,
  toggleInstructionsModal: PropTypes.func,
}

export default CourseRegisterInstructions
