import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { StoreContext } from 'store'
import { actions, enroll, getCourseAccessRequirements, userHasAccessToContent } from 'store/actions'
import { isLoading } from 'store/selectors'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { isEmptyArray } from 'utils/functional'
import AlertMessage from 'components/AlertMessage'
import BrandedAuthForm from 'components/BrandedAuthForm'
import Spinner from 'components/Spinner'
import { Modal } from 'components'
import useEnrollment from './useEnrollment'

const ChooseCourseSection = () => {
  const { api, dispatch, courseAccessRequirements, userAq, userAqHasAccess } =
    useContext(StoreContext)
  const navigate = useNavigate()
  const { customerSlug, vbid } = useParams()
  const { t, i18n } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const [courseSectionChosen, setCourseSectionChosen] = useState()
  const [newCourseKey, setNewCourseKey] = useState('')
  const [newCourseKeyError, setNewCourseKeyError] = useState('')
  const [errorMessages, setErrorMessages] = useState('')
  const [hasSubmittedCourseKey, setHasSubmittedCourseKey] = useState(false)
  const [displayConfirmEnrollDialog, setDisplayConfirmEnrollDialog] = useState(false)
  const isEnrollingToSelfStudyCourseSection = useRef(false)
  const isRedirectingToCourse = useRef(false)
  const choseInstructorLed = courseSectionChosen === 'instructor-led'
  const choseSelfStudy = courseSectionChosen === 'self-study'

  const {
    getCourseAccessRequirementsActionLoaded,
    getCourseAccessRequirementsActionHasErrors,
    didEnroll,
    canEnrollToCourse,
    userIsEnrolled,
    hasCheckedLicense,
    shouldCheckUserHasAccessToContent,
    stateCleanup,
  } = useEnrollment()

  const loginPageLinkAnchorText = `${window.location.host}/${customerSlug}`
  const loginPageLinkUrl = `${window.location.origin}/${customerSlug}`

  const courseKey = choseSelfStudy ? vbid : newCourseKey
  const courseUrl = bpe.coursePageUrl({ courseKey })
  const paymentUrl = bpe.payCoursePageUrl({ courseKey })

  const isApiLoading = isLoading(api)

  const shouldDisplayEnrollPreview =
    choseInstructorLed &&
    hasSubmittedCourseKey &&
    !errorMessages &&
    getCourseAccessRequirementsActionLoaded &&
    !getCourseAccessRequirementsActionHasErrors

  const shouldEnrollToSelfStudyCourseSection =
    !isApiLoading && !didEnroll && canEnrollToCourse && choseSelfStudy

  // Redirects to course TOC
  const shouldRedirectToCourse =
    ((choseInstructorLed && hasSubmittedCourseKey) ||
      (choseSelfStudy && isEnrollingToSelfStudyCourseSection.current)) &&
    !isApiLoading &&
    userIsEnrolled &&
    hasCheckedLicense &&
    (!courseAccessRequirements.paymentRequired || userAqHasAccess.status !== 403)

  const shouldRedirectToPayment =
    ((choseInstructorLed && hasSubmittedCourseKey) ||
      (choseSelfStudy && isEnrollingToSelfStudyCourseSection.current)) &&
    !isApiLoading &&
    userIsEnrolled &&
    hasCheckedLicense &&
    courseAccessRequirements.paymentRequired &&
    userAqHasAccess.status === 403

  const backToCourseKey = (e) => {
    e.preventDefault()
    stateCleanup()
    setHasSubmittedCourseKey(false)
  }

  const backToCourseSelection = (e) => {
    e.preventDefault()
    stateCleanup()
    setErrorMessages('')
    setNewCourseKey('')
    setCourseSectionChosen()
  }

  const renderFormFeedback = (messages, feedbackFor) =>
    Array.isArray(messages) ? (
      messages.map((message, index) => (
        <FormFeedback key={`form_feedback_${feedbackFor}_${index}`}>{message}</FormFeedback>
      ))
    ) : (
      <FormFeedback>{messages}</FormFeedback>
    )

  const getHandleInputChange = (setValue, setError) => (event) => {
    const {
      target: { name, value },
    } = event
    validateEmptyField(name, value, setError)
    setValue(value)
  }

  const validateEmptyField = (name, value, setError) => {
    if (!value) {
      const error = t(`courses.empty.${name}`)
      setError(error)
      return false
    }
    setError('')
    return true
  }

  const handleSubmitKey = (event) => {
    event.preventDefault()

    const isValidCourseKey = validateEmptyField('newCourseKey', newCourseKey, setNewCourseKeyError)

    if (!isValidCourseKey) {
      return
    }

    setHasSubmittedCourseKey(true)
    setErrorMessages('')
    getCourseAccessRequirements({ dispatch, courseKey: newCourseKey })
  }

  const handleEnroll = (event) => {
    event.preventDefault()
    enroll({ dispatch, courseKey, data: { user: userAq } })
  }

  const handleInstructorLedCourseSection = useCallback(
    (event) => {
      event.preventDefault()
      setErrorMessages('')
      stateCleanup()
      setCourseSectionChosen('instructor-led')
      isEnrollingToSelfStudyCourseSection.current = false
    },
    [stateCleanup],
  )

  const handleSelfStudyCourseSection = useCallback(
    (event) => {
      event.preventDefault()
      setErrorMessages('')
      stateCleanup()
      setCourseSectionChosen('self-study')
      setDisplayConfirmEnrollDialog(true)
    },
    [stateCleanup],
  )

  const handleEnrollToSelfStudyCourseSection = (event) => {
    event.preventDefault()
    isEnrollingToSelfStudyCourseSection.current = true
    getCourseAccessRequirements({ dispatch, courseKey })
    setDisplayConfirmEnrollDialog(false)
  }

  const validateCourseAccessRequirements = useCallback(() => {
    if (
      (choseInstructorLed && !hasSubmittedCourseKey) ||
      (choseSelfStudy && !isEnrollingToSelfStudyCourseSection.current)
    ) {
      return
    }
    let feedback = ''
    if (!getCourseAccessRequirementsActionLoaded) return
    if (!courseAccessRequirements && getCourseAccessRequirementsActionHasErrors) {
      feedback = `${t('courses.courseDoesNotExist')}.`
    } else {
      const { courseEnded, allowStandardRegistration, enrollmentStatus } = courseAccessRequirements
      switch (true) {
        case courseEnded:
          // Error message indicates the course has ended
          feedback = t('courses.errors.courseEnded')
          break
        case allowStandardRegistration === false:
          // Error message indicates course only accepts registration via LTI
          feedback = t('courses.errors.courseDoesOnlyAllowLtiRegistration')
          break
        case enrollmentStatus === 'Valid' || enrollmentStatus === 'Trial':
          // Error message indicates user is already enrolled and provides a link to the course
          feedback = t('courses.errors.courseEnrollmentValid')
          break
        default:
          break
      }
    }
    if (feedback) {
      isEnrollingToSelfStudyCourseSection.current = false
    }
    setErrorMessages(feedback)
  }, [
    choseInstructorLed,
    choseSelfStudy,
    courseAccessRequirements,
    getCourseAccessRequirementsActionHasErrors,
    getCourseAccessRequirementsActionLoaded,
    hasSubmittedCourseKey,
    t,
  ])

  const renderErrorMessages = () => {
    if (!errorMessages) return
    return (
      <AlertMessage color="danger" className="mt-4">
        {errorMessages}
        {(courseAccessRequirements?.enrollmentStatus === 'Valid' ||
          courseAccessRequirements?.enrollmentStatus === 'Trial') && (
          <>
            {' '}
            <Link to={courseUrl}>{courseAccessRequirements.title}</Link>
          </>
        )}
      </AlertMessage>
    )
  }

  // Effects

  useEffect(() => {
    validateCourseAccessRequirements()
  }, [
    courseAccessRequirements,
    getCourseAccessRequirementsActionHasErrors,
    validateCourseAccessRequirements,
  ])

  useEffect(() => {
    if (shouldEnrollToSelfStudyCourseSection) {
      enroll({ dispatch, courseKey, data: { user: userAq } })
    }
  }, [courseKey, dispatch, shouldEnrollToSelfStudyCourseSection, userAq])

  useEffect(() => {
    if (shouldCheckUserHasAccessToContent) {
      userHasAccessToContent({ dispatch, courseKey })
    }
  }, [courseKey, dispatch, shouldCheckUserHasAccessToContent])

  useEffect(() => {
    if (shouldRedirectToCourse && !isRedirectingToCourse.current) {
      isRedirectingToCourse.current = true

      dispatch({
        type: actions.COURSE_MESSAGES_SET,
        payload: {
          course: courseKey,
          message: `${t('courses.enrollSuccess')} ${courseAccessRequirements.title}`,
          shown: false,
        },
      })

      stateCleanup()
      navigate(courseUrl)
    }
  }, [
    courseAccessRequirements,
    courseKey,
    courseUrl,
    dispatch,
    navigate,
    shouldRedirectToCourse,
    stateCleanup,
    t,
  ])

  useEffect(() => {
    if (shouldRedirectToPayment) {
      navigate(paymentUrl)
    }
  }, [shouldRedirectToPayment, paymentUrl, navigate])

  return (
    <BrandedAuthForm customerSlug={customerSlug} form="signin" hasVbid={!!vbid}>
      {(!courseSectionChosen || choseSelfStudy) && (
        <fieldset className="form__fieldset" disabled={isApiLoading}>
          <h1 className="form__title">{t('login.chooseCourseSection')}</h1>
          <p>
            <Trans
              i18nKey="login.chooseCourseSectionNote"
              loginPageLinkAnchorText={loginPageLinkAnchorText}
            >
              <strong>Note:</strong> For future sign ins, use your VitalSource Bookshelf credentials
              at <a href={loginPageLinkUrl}>{{ loginPageLinkAnchorText }}</a>.
            </Trans>
          </p>

          <h2 className="form__subtitle">{t('login.instructorLedTitle')}</h2>
          <p>{t('login.instructorLedDescription')}</p>
          <Button fullWidth onClick={handleInstructorLedCourseSection} type="button">
            {t('login.instructorLedButton')}
          </Button>

          <div className="form__separator" role="separator">
            <span className="text-uppercase">{t('login.orAsOptionsSeparator')}</span>
          </div>

          <h2 className="form__subtitle">{t('login.selfStudyTitle')}</h2>
          <p>{t('login.selfStudyDescription')}</p>

          {renderErrorMessages()}

          <Button fullWidth onClick={handleSelfStudyCourseSection} type="button">
            {isApiLoading ? (
              <Spinner position="left" width={25} height={25} />
            ) : (
              t('login.selfStudyButton')
            )}
          </Button>
        </fieldset>
      )}
      {choseInstructorLed && !shouldDisplayEnrollPreview && (
        <fieldset className="form__fieldset" disabled={isApiLoading}>
          <h1 className="form__title">{t('login.enterCourseKey')}</h1>
          <p>{t('login.askCourseKey')}</p>

          {renderErrorMessages()}

          <FormGroup>
            <Label className="form__label" for="newCourseKey">
              {t('courses.courseKey')}
            </Label>
            <Input
              type="text"
              name="newCourseKey"
              id="newCourseKey"
              placeholder="---- ---- ---- ----"
              value={newCourseKey}
              onChange={getHandleInputChange(setNewCourseKey, setNewCourseKeyError)}
              invalid={!!newCourseKeyError}
              disabled={isApiLoading}
            />
            {renderFormFeedback(newCourseKeyError, 'newCourseKey')}
          </FormGroup>

          <Button fullWidth type="button" disabled={isApiLoading} onClick={handleSubmitKey}>
            {isApiLoading ? (
              <Spinner position="left" width={25} height={25} />
            ) : (
              t('courses.submit')
            )}
          </Button>

          <FormGroup className="mb-0">
            <ul className="form__links">
              <li>
                <Link className="form__link" onClick={backToCourseSelection} to="#">
                  {t('login.backToCourseSelection')}
                </Link>
              </li>
            </ul>
          </FormGroup>
        </fieldset>
      )}
      {shouldDisplayEnrollPreview && (
        <fieldset className="form__fieldset" disabled={isApiLoading}>
          <h1 className="form__title">{t('login.enrollInCourse')}</h1>
          <p>{t('login.pleaseConfirmCourse')}</p>
          <dl>
            <dt>{t('login.courseTitle')}</dt>
            <dd>{courseAccessRequirements?.title}</dd>

            <dt>{t('login.instructorName')}</dt>
            <dd>
              <ul className="list-unstyled">
                {isEmptyArray(courseAccessRequirements?.instructors) ? (
                  <li>-</li>
                ) : (
                  courseAccessRequirements?.instructors?.map((instructor) => (
                    <li key={instructor.id}>{`${instructor.firstName} ${instructor.lastName}`}</li>
                  ))
                )}
              </ul>
            </dd>

            {courseAccessRequirements?.startDate && (
              <>
                <dt>{t('login.startDate')}</dt>
                <dd>{moment(courseAccessRequirements?.startDate).format('l')}</dd>
              </>
            )}
          </dl>

          <Button fullWidth type="button" disabled={isApiLoading} onClick={handleEnroll}>
            {isApiLoading ? <Spinner position="left" width={25} height={25} /> : t('login.enroll')}
          </Button>

          <FormGroup className="mb-0">
            <ul className="form__links">
              <li>
                <Link className="form__link" onClick={backToCourseKey} to="#">
                  {t('login.backToCourseKey')}
                </Link>
              </li>
            </ul>
          </FormGroup>
        </fieldset>
      )}
      {displayConfirmEnrollDialog && (
        <Modal
          modalBody={<div id="message">{t('login.confirmEnrollDescription')}</div>}
          modalHeader={<>{t('login.confirmEnrollTitle')}</>}
          modalFooter={
            <>
              <Button variant="text" onClick={() => setDisplayConfirmEnrollDialog(false)}>
                {t('login.cancelButton')}
              </Button>
              <Button onClick={handleEnrollToSelfStudyCourseSection}>{t('login.enroll')}</Button>
            </>
          }
          open={true}
          centered
        />
      )}
    </BrandedAuthForm>
  )
}

export default ChooseCourseSection
