import React, { useCallback, useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import AlertMessage from 'components/AlertMessage'
import { ExclamationCircleOutline } from 'components/LXIcons/LXIcons'
import Newrelic from 'utils/newrelic'
import { StoreContext } from 'store'
import CurrentTimezone from 'components/CurrentTimezone'
import Footer from 'components/Footer'
import PageHeader from 'components/PageHeader'
import Spinner from 'components/Spinner'
import AssignmentsTable from 'components/AssignmentsTable'
import AssignmentTypeSelector from 'components/AssignmentTypeSelector'
import AssignmentEditBtn from 'components/AssignmentEditBtn'
import AssignmentModal from 'components/AssignmentModal'
import AllowLateWorkSwitch from 'components/AllowLateWorkSwitch'
import SmartForm from 'components/Forms/SmartForm'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import {
  DEFAULT_DRAWER_ID,
  EXCEPTIONS_DRAWER_ID,
  USER_ROLES,
  UNITS_AND_MODULES_DRAWER_ID,
} from 'utils/constants'
import { useAssignments, useAssignmentTagsReducer } from 'utils/customHooks'
import { actionHasErrors, isActionLoaded } from 'store/selectors'
import { unloadCourse, unsetCourseSchedule, updateCourseSettings } from 'store/actions'
import CourseSelector from 'components/CourseSelector'
import Tooltip from 'components/Tooltip'
import { withAssignmentsSettingsContext } from 'hoc'
import { useSettingsContext } from './store/settingsContext'
import {
  openDefaultSettingsDrawer,
  openExceptionsSettingsDrawer,
  setAssignmentTagsActions,
  setAssignmentTagsState,
  unsetAssignmentSelection,
  openUnitsAndModulesDrawer,
} from './store/actions'
import Exceptions from 'components/Forms/Exceptions'
import UnitsAndModulesForm from 'components/Forms/UnitsAndModulesForm'
import { ReactComponent as InfoOutline } from 'images/information-outline.svg'
import SettingsDrawer from './SettingsDrawer'

const Settings = () => {
  const { courseKey } = useParams()
  const navigate = useNavigate()
  const { assignments, api, course, courseSettings, dispatch, userAq } = useContext(StoreContext)
  const { assignmentSelection, assignmentTypes, dispatchSettingsAction, isSettingsDrawerOpen } =
    useSettingsContext()
  const { t, i18n } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const isInstructor = course?.enrollment?.role === USER_ROLES.INSTRUCTOR
  const isAdmin = !!userAq && userAq?.is_admin
  const [isAllAssignmentsDataLoaded] = useAssignments({ courseKey })
  const [assignmentModal, setAssignmentModal] = useState(null)
  const [assignmentTagsState, assignmentTagsActions] = useAssignmentTagsReducer(assignmentTypes)

  const defaultSettingsDrawerTitle = assignmentModal
    ? 'settingsDrawer.settingsTitle'
    : 'settingsDrawer.editSelectionTitle'

  const handleEditSelections = useCallback(() => {
    openDefaultSettingsDrawer({ dispatchSettingsAction })
  }, [dispatchSettingsAction])

  const handleManageExceptions = useCallback(() => {
    openExceptionsSettingsDrawer({ dispatchSettingsAction })
  }, [dispatchSettingsAction])

  const handleEditUnitOrModule = useCallback(() => {
    openUnitsAndModulesDrawer({ dispatchSettingsAction })
  }, [dispatchSettingsAction])

  const onAllowLateWork = (isAllowed) => {
    updateCourseSettings({ dispatch, courseKey, settings: { accept_late_work: isAllowed } })
  }

  const onOpenModule = (assignment) => {
    unsetAssignmentSelection({ dispatchSettingsAction })
    setAssignmentModal(assignment)
  }

  const onCourseSelectorChange = ({ courseKey }) => navigate(bpe.settingsPageUrl({ courseKey }))

  const closeAssignmentModal = () => setAssignmentModal(null)

  const assignmentEditSubmitted = isActionLoaded(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditHasErrors = actionHasErrors(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditSucceeded = assignmentEditSubmitted && !assignmentEditHasErrors

  const hasErrorsGettingAssignments = actionHasErrors(api, 'ASSIGNMENTS_GET')
  const hasErrorsGettingAssignmentTypes = actionHasErrors(api, 'ASSIGNMENT_TYPES_GET')
  const hasErrorsGettingCourse = actionHasErrors(api, 'COURSE_GET')
  const hasErrorsGettingCourseSettings = actionHasErrors(api, 'COURSE_SETTINGS_GET')
  const hasErrorsGettingAssignmentsData =
    hasErrorsGettingAssignments ||
    hasErrorsGettingAssignmentTypes ||
    hasErrorsGettingCourse ||
    hasErrorsGettingCourseSettings

  useEffect(() => {
    if (course && course.title) document.title = course.title
  }, [course])

  useEffect(() => {
    if (assignmentEditSucceeded) {
      assignmentTagsActions?.clearTags({ propagate: false })
    }
  }, [assignmentEditSucceeded, assignmentTagsActions])

  useEffect(() => {
    if (assignmentTagsActions && dispatchSettingsAction) {
      setAssignmentTagsActions({ dispatchSettingsAction, assignmentTagsActions })
    }
  }, [assignmentTagsActions, dispatchSettingsAction])

  useEffect(() => {
    if (assignmentTagsState && dispatchSettingsAction) {
      setAssignmentTagsState({ dispatchSettingsAction, assignmentTagsState })
    }
  }, [assignmentTagsState, dispatchSettingsAction])

  useEffect(() => {
    return function cleanup() {
      unloadCourse({ dispatch })
      unsetCourseSchedule({ dispatch })
    }
  }, [dispatch])

  useEffect(() => {
    Newrelic.setPageViewName('course/settings')
  }, [])

  if (course && !((isAdmin || isInstructor) && course?.enableInstructorSettings)) {
    const courseUrl = bpe.coursePageUrl({ courseKey })
    return <Navigate to={courseUrl} />
  }

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          overview: true,
          'assignment-settings-page': true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        <PageHeader>
          <div className="assignment-settings-header">
            <h1 className="courses-mast__title">{t('assignmentsSettings.headerTitle')}</h1>
            <CourseSelector
              currentCourseKey={courseKey}
              labelledBy="assignments-title"
              onChange={onCourseSelectorChange}
              size="large"
            />
          </div>
        </PageHeader>
        <Container>
          <Row>
            <Col>
              {hasErrorsGettingAssignmentsData && (
                <AlertMessage color="danger" icon={<ExclamationCircleOutline />}>
                  {t('assignmentsSettings.errors.gettingAssignments')}
                </AlertMessage>
              )}
              {!isAllAssignmentsDataLoaded && <Spinner />}
              {isAllAssignmentsDataLoaded && !hasErrorsGettingAssignmentsData && (
                <div>
                  <div className="details-row">
                    <div className="details">
                      <ul>
                        <li>{t('assignmentsSettings.descriptionBullet1')}</li>
                        <li>
                          {t('assignmentsSettings.descriptionBullet2')}
                          <Tooltip title={t('assignmentsSettings.tooltip')}>
                            <InfoOutline className="info-icon" />
                          </Tooltip>
                        </li>
                        <li>{t('assignmentsSettings.descriptionBullet3')}</li>
                      </ul>
                    </div>
                    <div className="late-work">
                      <AllowLateWorkSwitch
                        onAllowLateWork={onAllowLateWork}
                        lateWorkAllowed={courseSettings?.acceptLateWork}
                      />
                    </div>
                  </div>
                  <div className="filters-row">
                    <AssignmentTypeSelector />
                    <AssignmentEditBtn
                      nSelectedAssignments={assignmentSelection?.length}
                      handleEditSelections={handleEditSelections}
                      handleManageExceptions={handleManageExceptions}
                    />
                  </div>
                  <div className="table-row">
                    {/* {assignmentsStatus.render && <Spinner position="center" />} */}
                    <AssignmentsTable
                      data={assignments || []}
                      onOpenModule={onOpenModule}
                      handleEditUnitOrModule={handleEditUnitOrModule}
                    />
                  </div>
                  <CurrentTimezone showAsterisk timezone={userAq?.time_zone} />
                  <SettingsDrawer
                    title={t(defaultSettingsDrawerTitle)}
                    open={isSettingsDrawerOpen[DEFAULT_DRAWER_ID]}
                    drawerId={DEFAULT_DRAWER_ID}
                  >
                    <SmartForm courseKey={courseKey} />
                  </SettingsDrawer>
                  <SettingsDrawer
                    title={t('settingsDrawer.manageExceptionsTitle')}
                    open={isSettingsDrawerOpen[EXCEPTIONS_DRAWER_ID]}
                    drawerId={EXCEPTIONS_DRAWER_ID}
                  >
                    <Exceptions courseKey={courseKey} />
                  </SettingsDrawer>
                  <SettingsDrawer
                    title={t('settingsDrawer.editUnitOrModuleTitle')}
                    open={isSettingsDrawerOpen[UNITS_AND_MODULES_DRAWER_ID]}
                    drawerId={UNITS_AND_MODULES_DRAWER_ID}
                  >
                    <UnitsAndModulesForm courseKey={courseKey} />
                  </SettingsDrawer>
                </div>
              )}
              <AssignmentModal
                assignment={assignmentModal}
                closeModal={closeAssignmentModal}
                courseKey={courseKey}
                onAssignmentEdit={handleEditSelections}
              />
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default React.memo(withAssignmentsSettingsContext(Settings))
