import React, { Suspense } from 'react'
import { Store } from 'store'
import Router, { history } from './router'
import Spinner from 'components/Spinner'
import ErrorBoundary from 'components/ErrorBoundary'
import CustomThemeProvider from 'MUI/CustomThemeProvider'
import './i18n'

const App = () => (
  <Store>
    <Suspense fallback={<Spinner />}>
      <CustomThemeProvider>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Router history={history} />
          </Suspense>
        </ErrorBoundary>
      </CustomThemeProvider>
    </Suspense>
  </Store>
)

export default App
