import createColorPalette from './createColorPalette'

const cheddar = {
  1: '#fef3e9',
  2: '#fec491',
  3: '#fead64',
  4: '#f58525',
  5: '#e06107',
  6: '#ca500f',
  7: '#b0430c',
  8: '#983600',
  9: '#772700',
  10: '#571d00',
  inflection: 6,
}

export default createColorPalette(cheddar, 'cheddar')
