import createColorPalette from './createColorPalette'

const green = {
  1: '#edf7ed',
  2: '#d1ead1',
  3: '#a3d4a3',
  4: '#79c679',
  5: '#35a335',
  6: '#048a04',
  7: '#047103',
  8: '#015e02',
  9: '#024d03',
  10: '#023302',
  inflection: 6,
}

export default createColorPalette(green, 'green')
