import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { updateProfile } from 'store/actions'
import { isLoading } from 'store/selectors'
import { StoreContext } from 'store'
import Spinner from 'components/Spinner'
import { useFormHook } from 'utils/customHooks'
import { isEmptyObject } from 'utils/functional'
import { withCurrentUser } from 'hoc'
import classnames from 'classnames'

const ProfileSettings = ({ profile, currentUser }) => {
  const { t } = useTranslation()
  const { api, dispatch } = useContext(StoreContext)

  const submitForm = (fields) => {
    const { firstName, lastName, timezone } = fields

    updateProfile({ dispatch, data: JSON.stringify({ firstName, lastName, timezone }) })
  }

  const validateForm = (inputs) => {
    let partialErrors = {}

    // First, validate required fields (all inputs)
    const requiredFields = Object.keys(inputs).reduce((result, fieldName) => {
      const fieldValue = inputs[fieldName]

      if (!fieldValue) {
        result[fieldName] = t('errors.isRequired')
      }
      return result
    }, {})

    if (!isEmptyObject(requiredFields)) {
      partialErrors = { ...requiredFields }
      return partialErrors
    }

    if (!isEmptyObject(partialErrors)) {
      return partialErrors
    }

    return {}
  }

  const {
    inputs: { firstName, lastName, timezone },
    errors,
    handleSubmit,
    handleInputChange,
  } = useFormHook(profile, submitForm, validateForm)

  const getTimezoneSelectOptions = () => {
    const { timezones } = profile

    const buildOption = ([timezoneValue, timezoneName]) => (
      <option key={timezoneValue} value={timezoneValue}>
        {timezoneName}
      </option>
    )

    return timezones.map(buildOption)
  }

  const isApiLoading = isLoading(api)

  return (
    <>
      <Form>
        {isApiLoading && <Spinner />}
        {!currentUser.is_bookshelf_user && (
          <>
            <FormGroup>
              <Label for="firstName" className={classnames({ 'text-danger': !!errors.firstName })}>
                {t('profile.settings.firstName')}
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={handleInputChange}
                disabled={isApiLoading}
                invalid={!!errors.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="lastName" className={classnames({ 'text-danger': !!errors.lastName })}>
                {t('profile.settings.lastName')}
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={handleInputChange}
                disabled={isApiLoading}
                invalid={!!errors.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label for="timezone">{t('profile.settings.timezone')}</Label>
          {profile.timezone && (
            <select
              name="timezone"
              id="timezone"
              className="form-control"
              value={timezone}
              onChange={handleInputChange}
              disabled={isApiLoading}
            >
              {getTimezoneSelectOptions()}
            </select>
          )}
        </FormGroup>
        <FormGroup className="mt-5">
          <Button onClick={handleSubmit} disabled={isApiLoading}>
            {t('generics.save')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

ProfileSettings.propTypes = {
  currentUser: PropTypes.object,
  profile: PropTypes.object,
}

export default withCurrentUser(ProfileSettings)
